import { BoxIcon } from 'lucide-react';

import { ReportFolder } from '@/api/useReportFoldersApi.ts';
import { IconAvatar } from '@/components/IconPicker/IconAvatar.tsx';
import { AvatarProps } from '@/components/ui/avatar';

export interface ProjectIconProps {
  project: Pick<ReportFolder, 'color' | 'icon'> | null | undefined;
  size?: AvatarProps['size'];
  className?: string;
}

export function ProjectIcon({ project, size, className }: ProjectIconProps) {
  return (
    <IconAvatar
      icon={project?.icon}
      color={project?.color}
      size={size}
      className={className}
      defaultIcon={<BoxIcon className="size-[1.5em]" />}
    />
  );
}
