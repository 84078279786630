import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import { array, mixed, number, object, string } from 'yup';

import {
  AnalyticFieldType,
  AnalyticQueryFilter,
  ColumnType,
  DateGranularity,
  FilterOperator,
} from './query';
import { useApi } from '../components/ApiContext';
import { DateRangePickerValue } from '../components/DateRangePicker';
import { getDateRangeOrPreset } from '../components/DateRangePicker/presets';
import { useFilters } from '../components/FilterV2/FilterContext';
import {
  EFFECTIVE_DATE_KEY,
  useFilterActions,
} from '../components/FilterV2/FilterProvider/FilterActionsContext';
import { DATE_OPERATORS } from '../components/FilterV2/filterOperatorOptions';
import { isValidFilter } from '../components/FilterV2/isValidFilter';
import { QueryOptions } from '../utils/types';
import useCurrentOrganizationSlug from '../utils/useCurrentOrganizationSlug';

type AnalyticsQueryResult = Record<string, string | boolean | number>;

export type ChartErrorCode =
  | 'NO_QUERY_SELECTED'
  | 'UNKNOWN_VIZ_TYPE'
  | 'NO_DATA'
  | 'NO_MEASURE'
  | 'NO_MEASURE_2'
  | 'NO_DIMENSION'
  | 'NO_DIMENSION_2'
  | 'PERMISSION_DENIED';

class QueryError extends Error {
  code?: ChartErrorCode;

  constructor(message: string, code?: ChartErrorCode) {
    super(message);
    this.code = code;
  }
}

export enum Aggregation {
  COUNT = 'count',
  COUNT_DISTINCT = 'count_distinct',
  MEDIAN = 'median',
  AVG = 'avg',
  SUM = 'sum',
  MIN = 'min',
  MAX = 'max',
}

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export interface AnalyticQueryField {
  field: string;
  formula?: Aggregation;
  granularity?: DateGranularity;
  isCalculatedField?: boolean;
}

export interface AnalyticQueryOrdering {
  field: string;
  direction?: Direction;
}

type AnalyticQuerySort =
  | `-${AnalyticQueryOrdering['field']}`
  | AnalyticQueryOrdering['field'];

export type Format =
  | 'chart'
  | 'table'
  | 'scorecard'
  | 'flow'
  | 'scatter'
  | 'heatmap'
  | 'csv'
  | undefined;

export interface Formula {
  label?: string;
  formula: string;
}

export type QueryComparison =
  | {
      type: 'overall';
    }
  | {
      type: 'date';
      dateOffset:
        | { value: string[] }
        | { value: number; granularity: DateGranularity };
    };

export type ComparisonType = QueryComparison['type'];

export interface QueryVizConfig {
  totals?: boolean;
  transpose?: boolean;
  compareTo?: QueryComparison;
  pivots?: string[];
}

function cleanVizConfig(config: QueryVizConfig | null | undefined) {
  if (!config) {
    return null;
  }

  return pick(config, ['totals', 'transpose', 'compareTo', 'pivots']);
}

export interface AnalyticQuery<T extends Format = undefined> {
  table: string;
  fields?: AnalyticQueryField[];
  filters?: AnalyticQueryFilter[];
  sorts?: AnalyticQuerySort[];
  limit?: number;
  offset?: number;
  format?: T;
  formulas?: Formula[];
  segments?: string[];
  calculatedFields?: string[];
  vizConfig?: QueryVizConfig | null | undefined;
}

export const filterSchema = object({
  field: string().required(),
  operator: string().oneOf(Object.values(FilterOperator)).required(),
  value: mixed().notRequired(),
});

export const analyticQuerySchema = object({
  table: string().required(),
  fields: array()
    .of(
      object({
        field: string().required(),
        formula: string().oneOf(Object.values(Aggregation)).notRequired(),
        granularity: string()
          .oneOf(Object.values(DateGranularity))
          .notRequired(),
      }),
    )
    .default([]),
  filters: array().of(filterSchema).notRequired().default([]),
  sorts: array().of(string().required()).default([]).notRequired(),
  limit: number().notRequired(),
  offset: number().notRequired(),
  format: string()
    .oneOf(['chart', 'table', 'scorecard', 'flow', 'scatter', 'csv'])
    .notRequired(),
  formulas: array()
    .of(
      object({
        label: string().notRequired(),
        formula: string().required(),
      }),
    )
    .notRequired()
    .default([]),
  vizConfig: mixed().notRequired(),
});

export type TimeGranularity =
  | 'day'
  | 'month'
  | 'week'
  | 'quarter'
  | 'year'
  | 'fiscal_month'
  | 'fiscal_quarter'
  | 'fiscal_year';

export type XAxisConfig =
  | {
      type: 'category';
      label?: string;
      showLabel?: boolean;
      showTicks?: boolean;
      showGridlines?: boolean;
      reverse?: boolean;
      groupField?: string;
    }
  | {
      type: 'time';
      label?: string;
      showLabel?: boolean;
      showTicks?: boolean;
      showGridlines?: boolean;
      timeGranularity?: TimeGranularity;
      reverse?: boolean;
      groupField?: string;
    };

interface YAxisConfig {
  label?: string;
  showLabel?: boolean;
  showGridLines?: boolean;
  showTicks?: boolean;
  position?: 'left' | 'right';
  type?: 'linear' | 'logarithmic';
  valueFormat?: string;
}

interface FieldResult {
  field: string;
  label: string;
  columnType: ColumnType;
  type: AnalyticFieldType;
}

interface FieldsResult {
  measures: FieldResult[];
  dimensions: FieldResult[];
  parameters: FieldResult[];
}

export interface ChartResult {
  xAxis: XAxisConfig;
  yAxes?: YAxisConfig[];
  fields: FieldsResult;
  labels: string[];
  datasets: {
    label: string;
    field: string;
    group?: string;
    type?: string;
    yAxis?: number;
    valueFormat?: string;
    data: { value: number | null | undefined; drilldown?: AnalyticQuery }[];
  }[];
}

interface TableResultCell {
  value: string | boolean | number | null;
  drilldown?: AnalyticQuery;
  valueFormat?: string;
  type?: AnalyticFieldType;
}

export interface TableResultRow {
  [key: string]: TableResultCell | TableResultRow;
}

export interface TableResultColumn {
  id: string;
  header: string;
  total?: number;
  valueFormat?: string;
  type?: AnalyticFieldType;
  columns?: TableResultColumn[];
}

export interface TableResult {
  fields: FieldsResult;
  columns: TableResultColumn[];
  rows: TableResultRow[];
  offset: number;
  nextOffset?: number;
}

export interface ScorecardResult {
  label: string;
  description?: string;
  field: string;
  value: number;
  valueFormat?: string;
  drilldown?: AnalyticQuery;
  comparison?: number;
  goodValueIndicator?: 'high' | 'low';
}

export interface FlowResult {
  labels: string[];
  datasets: {
    label: string;
    fieldFrom: string;
    fieldTo: string;
    data: [from: string, to: string, weight: number][];
  }[];
  drilldowns: AnalyticQuery[];
}

export interface ScatterResult {
  labels: string[];
  x: string;
  y: string;
  datasets: {
    label: string;
    field: string;
    data: { x: number; y: number }[];
  }[];
}

export interface HeatmapResult {
  xAxis: XAxisConfig & { labels: string[] };
  yAxis: XAxisConfig & { labels: string[] };
  drilldowns: AnalyticQuery[];
  datasets: {
    label: string;
    field: string;
    data: [from: number, to: number, value: number][];
  }[];
}

export type Result<T extends Format> = T extends 'chart'
  ? ChartResult
  : T extends 'table'
    ? TableResult
    : T extends 'scorecard'
      ? ScorecardResult[]
      : T extends 'flow'
        ? FlowResult
        : T extends 'scatter'
          ? ScatterResult
          : T extends 'heatmap'
            ? HeatmapResult
            : T extends 'csv'
              ? string
              : AnalyticsQueryResult[];

export interface DetailedResult<T extends Format> {
  data: Result<T> | null | undefined;
  sql: string;
}

interface ErrorResult {
  error: string;
  code?: ChartErrorCode;
}

function isErrorResult(result: unknown): result is ErrorResult {
  return !Array.isArray(result) && !!(result as any).error;
}

function toCalculatedField(field: AnalyticQueryField | AnalyticQueryFilter) {
  const { isCalculatedField, ...rest } = field;

  if (isCalculatedField) {
    return {
      ...rest,
      // XXX: incredibly unfortunate that we have to do this, but the getWhiteListKeys result
      // for non-formatted queries is not working as expected
      // eslint-disable-next-line @typescript-eslint/naming-convention
      is_calculated_field: true,
    } as any;
  } else {
    return field;
  }
}
export function cleanQuery<T extends Format>(
  query: AnalyticQuery<T> | null | undefined,
): AnalyticQuery<T> | null {
  if (!query) {
    return null;
  }

  const finalQuery = {
    ...query,
    fields: query.fields?.filter(Boolean)?.map(toCalculatedField),
  };

  if (query.vizConfig) {
    finalQuery.vizConfig = cleanVizConfig(query.vizConfig);
  }

  if (query.filters) {
    finalQuery.filters = query.filters.map(toCalculatedField);
  }

  if (query.calculatedFields) {
    // Migrate calculatedFields to fields
    finalQuery.fields = finalQuery.fields ?? [];
    query.calculatedFields.forEach((field) => {
      finalQuery.fields!.push(
        toCalculatedField({ field, isCalculatedField: true }),
      );
    });
    delete finalQuery.calculatedFields;
  }

  return finalQuery;
}

export function getKeysWhitelist<T extends Format>(
  query: AnalyticQuery<T> | null | undefined,
) {
  if (!query || !query.format) {
    return false;
  }

  if (query.format === 'chart') {
    return undefined;
  }

  if (query.format === 'table') {
    return ['rows'];
  }
}

export function useRunQuery<T extends Format = undefined>(
  query: AnalyticQuery<T> | null | undefined,
) {
  const api = useApi();
  const organizationSlug = useCurrentOrganizationSlug();

  return async (
    queryOverrides: Partial<AnalyticQuery<T>> = {},
    signal: AbortSignal | undefined = undefined,
  ) => {
    if (!query) {
      return null;
    }

    const finalQuery = cleanQuery({
      ...query,
      ...queryOverrides,
    })!;

    const result = await api.post<DetailedResult<T>, AnalyticQuery<T>>(
      `orgs/${organizationSlug}/query/`,
      finalQuery,
      undefined,
      getKeysWhitelist(query),
      signal,
    );

    if (!result) {
      throw new QueryError('No data');
    }

    if (isErrorResult(result)) {
      throw new QueryError(result.error, result.code as ChartErrorCode);
    }

    return result as DetailedResult<T>;
  };
}

export function useAnalyticQuery<
  T extends Format = undefined,
  TSelectData = DetailedResult<T>,
>(
  query: AnalyticQuery<T> | null | undefined,
  options: QueryOptions<DetailedResult<T> | null, TSelectData> = {},
) {
  const cleanedQuery = cleanQuery(query);
  const runQuery = useRunQuery(cleanedQuery);
  return useQuery<TSelectData>({
    ...(options as any),
    queryKey: ['query', cleanedQuery],
    queryFn: () => runQuery(),
    throwOnError: false,
    staleTime: Infinity,
    enabled: !!cleanedQuery && options.enabled !== false,
  });
}

function buildTimestampFilter(
  field: string,
  value: DateRangePickerValue,
  initialOperator?: FilterOperator,
  granularity?: DateGranularity,
): AnalyticQueryFilter | null {
  const parsed = getDateRangeOrPreset(value);

  if (!parsed) {
    return null;
  }

  const { value: dateRange } = parsed;
  const operator = initialOperator ?? parsed.operator;

  const filterOperator = DATE_OPERATORS.flat().find(
    (operatorOption) =>
      operatorOption.value === operator &&
      !!operatorOption.isValueValid?.(dateRange),
  );

  if (!filterOperator) {
    return null;
  }

  const timestampFilter: AnalyticQueryFilter = {
    field,
    operator,
    value: filterOperator.toFilterValue?.(dateRange) ?? dateRange,
    format: 'date',
  };

  if (granularity) {
    timestampFilter.granularity = granularity;
  }

  return timestampFilter;
}

export function useQueryWithFilters<T extends Format = undefined>(
  query: AnalyticQuery<T> | null | undefined,
) {
  const { tableFilters, value } = useFilters();
  const { filterActions, value: actionsValue } = useFilterActions();

  return useMemo<AnalyticQuery<T> | null>(() => {
    if (!query || !query.fields || !query.fields.length) {
      return null;
    }

    const globalFiltersByField: Record<
      AnalyticQueryFilter['field'],
      AnalyticQueryFilter
    > = {};

    const filters: AnalyticQueryFilter[] = [];
    const segments: Set<string> = new Set();

    // XXX: loop through all available table filters to weed out the ones that
    // are from the previous filter version or not valid
    tableFilters
      .flatMap((table) => table.filters)
      .forEach((filter) => {
        const queryFilter = value[filter.field];

        if (!queryFilter || !isValidFilter(queryFilter)) {
          return;
        }

        if (filter.type === 'segment') {
          segments.add(filter.field);
        } else if (filter.type === 'calculatedField') {
          globalFiltersByField[filter.field] = {
            ...queryFilter,
            isCalculatedField: true,
          };
        } else {
          globalFiltersByField[queryFilter.field] = queryFilter;
        }
      });

    if (filterActions && actionsValue[EFFECTIVE_DATE_KEY]) {
      const dateFilter = buildTimestampFilter(
        '$date',
        actionsValue[EFFECTIVE_DATE_KEY],
      );
      if (dateFilter) {
        globalFiltersByField.$date = dateFilter;
      }
    }

    // Add the query's filters to the list of filters
    // and override any global filter with the same field.
    // Note that the query's filters can have duplicates,
    // and we want to keep all of them.
    if (query.filters) {
      query.filters.forEach((filter) => {
        if (globalFiltersByField[filter.field]) {
          delete globalFiltersByField[filter.field];
        }

        // For any timestamp filter, we want to push the parsed value
        if (filter.field === '$date' || filter.format === 'date') {
          const dateFilter = buildTimestampFilter(
            filter.field,
            filter.value as DateRangePickerValue,
            filter.operator,
            filter.granularity,
          );

          if (dateFilter) {
            filters.push(dateFilter);
          }
        } else {
          filters.push(filter);
        }
      });
    }

    // Add the global filters to the list of filters
    filters.push(...Object.values(globalFiltersByField));

    if (query.segments) {
      query.segments.forEach((segment) => {
        segments.add(segment);
      });
    }

    const finalQuery = { ...query };
    if (filters.length > 0) {
      finalQuery.filters = filters;
    }

    if (segments.size > 0) {
      finalQuery.segments = Array.from(segments);
    }

    return finalQuery;
  }, [tableFilters, actionsValue, query, value, filterActions]);
}

export function useFilteredAnalyticQuery<T extends Format = undefined>(
  query: AnalyticQuery<T> | null | undefined,
  options: QueryOptions<DetailedResult<T>> = {},
) {
  const cleanedQuery = cleanQuery(query);
  const finalQuery = useQueryWithFilters(cleanedQuery);
  const runQuery = useRunQuery(finalQuery);
  return useQuery<DetailedResult<T>>({
    ...(options as any),
    queryKey: ['query', finalQuery],
    queryFn: () => runQuery(),
    throwOnError: false,
    staleTime: Infinity,
    enabled: !!finalQuery && options.enabled !== false,
    retry: (failureCount, error) => {
      if (error instanceof QueryError) {
        return false;
      }

      return failureCount < 3;
    },
  });
}
