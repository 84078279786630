import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useGetPath } from './apiFactory';
import { Favourite } from './types/resources';
import { useApi } from '../components/ApiContext';
import { PaginatedQuery } from '../utils/types';
import useCurrentOrganizationSlug from '../utils/useCurrentOrganizationSlug';

const API_ENDPOINT = 'favourites';
const API_ACTION_FAVOURITE = 'favourite';

// Loading 20 Favourites by default.
// Will change the logic later in case users need more than 20 favourites in a list
const DEFAULT_PAGE_SIZE = 20;

export type Favouritable = 'report' | 'reportfolder';
const FAVOURITE_API: Record<Favouritable, string> = {
  report: 'reports',
  reportfolder: 'folders',
};

function useFavouritesApi() {
  const api = useApi();
  const organizationSlug = useCurrentOrganizationSlug();

  return useQuery({
    queryKey: [organizationSlug, API_ENDPOINT],
    queryFn: () =>
      api.get<PaginatedQuery<Favourite>>(`${API_ENDPOINT}/`, {
        organizationSlug,
        pageSize: DEFAULT_PAGE_SIZE,
      }),
    // Setting to Infinity to prevent re-fetching on in FavouriteMutationTrigger component
    staleTime: Infinity,
  });
}

export function useGetFavourites() {
  const favouritesQueryResult = useFavouritesApi();
  return useMemo(
    () => favouritesQueryResult.data?.results || [],
    [favouritesQueryResult],
  );
}

export function useFavouritesInvalidateQueries() {
  const organizationSlug = useCurrentOrganizationSlug();
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [organizationSlug, API_ENDPOINT],
    });
}

export function useIsFavourite(favouriteEntityName: Favouritable) {
  const favourites = useGetFavourites();
  return (favouriteId: string | undefined) =>
    favourites.some(
      ({ relatedObjectId, relatedModelName }) =>
        relatedObjectId === favouriteId &&
        relatedModelName === favouriteEntityName,
    );
}

export function useToggleFavourite(
  relatedModel: Favouritable,
  relatedObjectId: string,
) {
  const api = useApi();
  const isFavourite = useIsFavourite(relatedModel);
  const apiEndpoint = FAVOURITE_API[relatedModel];
  const getBasePath = useGetPath(
    'url',
    `${apiEndpoint}/${relatedObjectId}/${API_ACTION_FAVOURITE}`,
  );

  return () =>
    isFavourite(relatedObjectId)
      ? api.delete(getBasePath())
      : api.post(getBasePath());
}
