import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ReportCollaboratorRole } from '@/api/useReportsCollaboratorsApi.ts';
import { User } from '@/api/useUsersApi.ts';
import useCurrentOrganizationSlug from '@/utils/useCurrentOrganizationSlug.ts';

import createApi from './apiFactory';
import { useReportsInvalidate } from './useReportsApi';
import { useApi } from '../components/ApiContext';
import useMutationTrigger from '../utils/useMutationTrigger';

export interface ReportFolderReport {
  id: string;
  title: string;
  description?: string;
}

export interface ReportFolder {
  id: string;
  name: string;
  icon?: string | null | undefined;
  color?: string | null | undefined;
  description: string;
  numReports: number;
  viewerRole: ReportCollaboratorRole;
  createdBy: User;
  updatedAt: string;
  members: ReportMember[];
}

interface ReportMemberUser {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
}

interface ReportMemberTeam {
  id: string;
  name: string;
}

interface ReportMember {
  id: string;
  role: 'editor' | 'viewer';
  user?: ReportMemberUser;
  team?: ReportMemberTeam;
}

const {
  usePaginatedList,
  useGet,
  useSave,
  useInvalidate,
  useGetPath,
  useDelete,
} = createApi<ReportFolder>({
  basePath: 'folders',
  organisationSlugPosition: 'url',
  paginationType: 'page',
});

export {
  useGet as useGetReportFolder,
  usePaginatedList as useListReportFolders,
  useSave as useSaveReportFolder,
  useDelete as useDeleteReportFolder,
};

/**
 * Hook to resolve the story URL for a given path.
 * Stories can be viewed in the context of a project or not.
 */
export function useResolveStoryUrl() {
  const { organizationSlug, projectId } = useParams();

  return (storyId?: string, path?: string) => {
    let basePath = projectId
      ? `/${organizationSlug}/projects/${projectId}/stories`
      : `/${organizationSlug}/stories`;

    if (storyId) {
      basePath += `/${storyId}`;
    }

    if (path) {
      basePath += `/${path}`;
    }

    return basePath;
  };
}

export function useAddReportToFolder() {
  const api = useApi();
  const invalidate = useInvalidate();
  const getPath = useGetPath();
  const reportsInvalidate = useReportsInvalidate();
  const organizationSlug = useCurrentOrganizationSlug();
  const navigate = useNavigate();

  const handleAddReportToFolder = ({
    folderId,
    reportId,
  }: {
    folderId: string;
    reportId: string;
  }) => {
    const path = `${getPath(folderId)}add/`;
    return api.post<ReportFolder>(path, { report: reportId });
  };

  return useMutationTrigger(handleAddReportToFolder, {
    onSuccess: async (folder, _queryClient, value) => {
      if (folder) {
        toast.success('Story added to project', {
          action: {
            label: 'View',
            onClick: () => {
              navigate(
                `/${organizationSlug}/projects/${folder.id}/stories/${value.reportId}`,
              );
            },
          },
        });
      }
      await invalidate();
      return reportsInvalidate();
    },
  });
}

export function useRemoveReportFromFolder() {
  const api = useApi();
  const invalidate = useInvalidate();
  const getPath = useGetPath();
  const reportsInvalidate = useReportsInvalidate();

  const handleRemoveReportFromFolder = ({
    folderId,
    reportId,
  }: {
    folderId: string;
    reportId: string;
  }) => {
    const path = `${getPath(folderId)}remove/`;
    return api.post(path, { report: reportId });
  };

  return useMutationTrigger(handleRemoveReportFromFolder, {
    onSuccess: async () => {
      await invalidate();
      return reportsInvalidate();
    },
    successMessage: 'Story removed from project',
  });
}

export function createFolderMembersApi(folderId: string) {
  const folderMembersApi = createApi<ReportMember>({
    basePath: `folders/${folderId}/members`,
    organisationSlugPosition: 'url',
    paginationType: 'none',
  });

  // Unfortunately, we need to override the useSave hook b/c we're always passing in an id
  function useSaveFolderMember(isUpdate = false) {
    const api = useApi();
    const getPath = folderMembersApi.useGetPath();
    const invalidate = folderMembersApi.useInvalidate();
    const invalidateFolder = useInvalidate();

    return useMutationTrigger(
      (
        data: Pick<ReportMember, 'id' | 'role'> & {
          user?: string;
          team?: string;
        },
      ) =>
        isUpdate
          ? api.put(getPath(data.id), data)
          : api.post<ReportMember>(getPath(), data),
      {
        onSuccess: () => Promise.resolve([invalidate(), invalidateFolder()]),
      },
    );
  }

  return {
    useSaveFolderMember,
    useFolderMembers: folderMembersApi.useList,
    useFolderMember: folderMembersApi.useGet,
    useDeleteFolderMember: folderMembersApi.useDelete,
  };
}
