import { useRef } from 'react';
import { useIntl } from 'react-intl';

import {
  SearchResult,
  SearchType,
  useGetSearchPath,
} from '@/api/useSearchApi.ts';
import { useApi } from '@/components/ApiContext.ts';
import { IconAvatar } from '@/components/IconPicker/IconAvatar.tsx';
import { Avatar } from '@/components/ui/avatar.tsx';
import { pages } from '@/i18n';
import { ProjectIcon } from '@/pages/projects/components/ProjectIcon.tsx';
import { StoryIcon } from '@/pages/stories/components/StoryIcon.tsx';
import { PaginatedQuery } from '@/utils/types.ts';

export interface SearchResource {
  id: string;
  key: string;
  label: string;
  icon: React.ReactNode;
  type: SearchType;
  path: string;
}

function toUserItem(item: SearchResult<'user'>): SearchResource {
  const name = item.metadata.name || item.metadata.email;
  return {
    type: 'user',
    id: item.id,
    key: item.id,
    label: name,
    icon: <Avatar size="2xs" name={name} src={item.metadata.avatarUrl} />,
    path: `/admin/members/${item.id}`,
  };
}

function toTeamItem(item: SearchResult<'team'>): SearchResource {
  return {
    type: 'team',
    id: item.id,
    key: item.id,
    label: item.metadata.name,
    icon: <Avatar size="2xs" name={item.metadata.name} />,
    path: `/admin/teams/${item.id}`,
  };
}

function toReportItem(item: SearchResult<'report'>): SearchResource {
  return {
    type: 'report',
    id: item.id,
    key: item.id,
    label: item.metadata.title,
    icon: <StoryIcon size="2xs" story={item.metadata} />,
    path: `/stories/${item.id}`,
  };
}

function toReportBlockItem(item: SearchResult<'block'>): SearchResource {
  return {
    type: 'block',
    id: item.id,
    key: item.id,
    label: item.metadata.title,
    icon: <IconAvatar size="2xs" icon="chart-bar-big" />,
    path: `/stories/${item.metadata.reportId}#${item.id}`,
  };
}

function toExploreItem(item: SearchResult<'explore'>): SearchResource {
  return {
    type: 'explore',
    id: item.id,
    key: item.id,
    label: item.metadata.title,
    icon: <IconAvatar size="2xs" icon="compass" />,
    path: `/charts/${item.id}`,
  };
}

function toFolderItem(item: SearchResult<'folder'>): SearchResource {
  return {
    type: 'folder',
    id: item.id,
    key: item.id,
    label: item.metadata.title,
    icon: <ProjectIcon size="2xs" project={item.metadata} />,
    path: `/projects/${item.id}`,
  };
}

interface UseResourceSearchProps<T extends SearchType> {
  resources?: T[] | '*';
  excludeIds?: string[];
}

export function useResourceSearch<T extends SearchType>({
  resources,
  excludeIds = [],
}: UseResourceSearchProps<T> = {}) {
  const intl = useIntl();
  const api = useApi();
  const searchPath = useGetSearchPath();
  const abortController = useRef<AbortController | null>(null);

  return async (query: string) => {
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    const results = await api.get<PaginatedQuery<SearchResult<T>> | null>(
      searchPath(),
      {
        q: query,
        type: resources,
      },
      undefined,
      abortController.current.signal,
    );

    const resultsByType: {
      [key: string]: SearchResource[] | undefined;
    } = {};

    (
      results?.results?.filter(
        (item) => !excludeIds.includes(item.metadata.id),
      ) ?? []
    )
      .map((item) => {
        if (item.type === 'user') {
          return toUserItem(item);
        }
        if (item.type === 'team') {
          return toTeamItem(item);
        }
        if (item.type === 'report') {
          return toReportItem(item);
        }
        if (item.type === 'folder') {
          return toFolderItem(item);
        }
        if (item.type === 'block') {
          return toReportBlockItem(item);
        }
        if (item.type === 'explore') {
          return toExploreItem(item);
        }
        return null;
      })
      .filter(Boolean)
      .forEach((result) => {
        resultsByType[result.type] = resultsByType[result.type] || [];
        resultsByType[result.type]!.push(result);
      });

    const items = [];

    if (resultsByType.folder) {
      items.push({
        key: 'folders',
        label: intl.formatMessage(pages.projects),
        items: resultsByType.folder,
      });
    }

    if (resultsByType.user) {
      items.push({
        key: 'users',
        label: intl.formatMessage(pages.users),
        items: resultsByType.user,
      });
    }

    if (resultsByType.team) {
      items.push({
        key: 'teams',
        label: intl.formatMessage(pages.teams),
        items: resultsByType.team,
      });
    }

    if (resultsByType.report) {
      items.push({
        key: 'reports',
        label: intl.formatMessage(pages.stories),
        items: resultsByType.report,
      });
    }

    if (resultsByType.explore) {
      items.push({
        key: 'explore',
        label: intl.formatMessage(pages.savedCharts),
        items: resultsByType.explore,
      });
    }

    if (resultsByType.block) {
      items.push({
        key: 'blocks',
        label: intl.formatMessage(pages.storyBlocks),
        items: resultsByType.block,
      });
    }

    return items;
  };
}
