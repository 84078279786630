import { IconButton, SearchInput } from '@eqtble/ui';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useQuery } from '@tanstack/react-query';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { LucideProps } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import React, { Suspense, useMemo, useRef, useState } from 'react';

interface IconProps extends Omit<LucideProps, 'ref'> {
  name: keyof typeof dynamicIconImports;
}

const FALLBACK = (
  <div style={{ background: 'transparent', width: 24, height: 24 }} />
);

const iconCache: { [key: string]: any } = {};

const getCachedIcon = (name: keyof typeof dynamicIconImports) => {
  if (!iconCache[name]) {
    iconCache[name] = React.lazy(dynamicIconImports[name]);
  }
  return iconCache[name];
};

// Pre-load all icons
Object.keys(dynamicIconImports).forEach((name) => {
  getCachedIcon(name as any);
});

export function Icon({ name, ...props }: IconProps) {
  if (!dynamicIconImports[name]) {
    return name;
  }

  const LucideIcon = getCachedIcon(name);

  return (
    <Suspense fallback={FALLBACK}>
      <LucideIcon {...props} />
    </Suspense>
  );
}

const COLORS = [
  'default',
  'gray',
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
] as const;

const ICONS_PER_ROW = COLORS.length;
const iconsSearchIndexPromise = Promise.all([
  import('./icons.json'),
  import('fuse.js'),
])
  .then(([icons, fuse]) => [icons.default, fuse.default] as const)
  .then(([icons, Fuse]) => {
    const iconsList = Object.entries(icons).map(([key, value]) => ({
      key,
      value,
    }));
    const instance = new Fuse(iconsList, {
      keys: ['key', 'value'],
    });
    return [instance, iconsList] as const;
  });

export interface IconPickerProps {
  onSelectColor: (color: string) => void;
  onSelectIcon: (icon: string) => void;
}

export function IconPicker({ onSelectColor, onSelectIcon }: IconPickerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');

  const icons = useQuery({
    queryKey: ['icons'],
    queryFn: () => iconsSearchIndexPromise,
  });

  const filteredIcons = useMemo(() => {
    if (!icons.data) {
      return [];
    }

    const [fuse, iconsList] = icons.data;

    if (!search) {
      return iconsList.map((result) => result.key);
    }

    return fuse.search(search).map((result) => result.item.key);
  }, [icons, search]);

  const groupedIcons = useMemo(() => {
    // Group icons by row
    const rows = [];
    for (let i = 0; i < filteredIcons.length; i += ICONS_PER_ROW) {
      rows.push(filteredIcons.slice(i, i + ICONS_PER_ROW));
    }
    return rows;
  }, [filteredIcons]);

  const virtualizer = useVirtualizer({
    getScrollElement: () => ref.current,
    estimateSize: () => 32,
    count: groupedIcons.length,
    overscan: 20
  });

  if (icons.isLoading) {
    return null;
  }

  function renderRow({ key, index, size, start }: VirtualItem<HTMLElement>) {
    return (
      <div
        key={key}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: size,
          transform: `translateY(${start}px)`,
        }}
        className="flex w-full items-center"
      >
        {groupedIcons[index].map((icon) => (
          <IconButton
            as={DropdownMenuPrimitive.Item}
            aria-label={icon}
            type="button"
            size="sm"
            key={icon}
            onClick={() => onSelectIcon(icon)}
          >
            <Icon name={icon as any} className="size-[1.2em]" />
          </IconButton>
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full items-center justify-center space-x-2 px-3 py-2">
        {COLORS.map((color) => (
          <button
            key={color}
            type="button"
            className="size-6 cursor-pointer rounded-full border-2 border-none hover:shadow focus:outline-none"
            data-color={color}
            onClick={() => onSelectColor(color)}
            aria-label={`Select ${color}`}
          />
        ))}
      </div>
      <div className="p-2">
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onReset={() => setSearch('')}
        />
      </div>
      <div className="max-h-96 w-full overflow-y-auto" ref={ref}>
        <div
          style={{ height: virtualizer.getTotalSize() }}
          className="relative w-full"
        >
          {virtualizer.getVirtualItems().map(renderRow)}
        </div>
      </div>
    </>
  );
}
