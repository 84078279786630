/* eslint-disable @typescript-eslint/naming-convention */
import { format, isAfter, isValid, parse, parseISO, toDate } from 'date-fns';

export const GRANULARITY_FORMATS = {
  day: 'LLL do, y',
  month: 'LLL y',
  week: 'LLL do',
  quarter: 'QQQ y',
  year: 'y',
  fiscal_month: "'M'M 'FY'y",
  fiscal_quarter: "QQQ 'FY'y",
  fiscal_year: "'FY'y",
};

export type Granularity = keyof typeof GRANULARITY_FORMATS;

export const DATE_FORMAT = 'MM/dd/yyyy';

function isValidDate(date: unknown): date is Date {
  return isValid(date) && isAfter(date as Date, new Date('1/1/1000'));
}

export function parseDate(value: any, dateFormat = DATE_FORMAT) {
  if (value === null || typeof value === 'undefined') {
    return null;
  }

  let parsedDate;
  const type = typeof value;

  if (type === 'number' || value instanceof Date) {
    parsedDate = toDate(value);
  } else if (type === 'string') {
    parsedDate = parse(value, dateFormat, new Date());

    if (!isValidDate(parsedDate)) {
      parsedDate = parseISO(value);
    }
  }

  return isValid(parsedDate) ? parsedDate! : null;
}

export function formatDate(
  value: string | Date | null | undefined,
  dateFormat = DATE_FORMAT,
) {
  let parsedDate;
  if (typeof value === 'string') {
    parsedDate = parseDate(value);
  } else {
    parsedDate = value;
  }

  if (!parsedDate) {
    return '';
  }

  return format(parsedDate, dateFormat);
}

export function formatGranularityDate(
  value: string | Date | null | undefined,
  granularity: Granularity | null | undefined,
) {
  if (!granularity) {
    return formatDate(value);
  }

  return formatDate(value, GRANULARITY_FORMATS[granularity]);
}

export function parseGranularityDate(
  value: string,
  granularity: Granularity | undefined,
) {
  if (!granularity) {
    return parseDate(value);
  }
  return parseDate(value, GRANULARITY_FORMATS[granularity]);
}

export function getGranularityFromValue(
  value: string,
): Granularity | undefined {
  /** Attempts to parse a date string and determine the granularity */

  if (!value) {
    return;
  }

  if (value.startsWith('FY')) {
    return 'fiscal_year';
  }

  for (const [granularity, format] of Object.entries(GRANULARITY_FORMATS)) {
    if (isValid(parse(value, format, new Date()))) {
      return granularity as Granularity;
    }
  }
}
