/* eslint-disable @typescript-eslint/naming-convention */
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/browser';

import posthog from './posthog';

let segment: AnalyticsBrowser | undefined;
if (process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY) {
  segment = AnalyticsBrowser.load({
    writeKey: process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY,
  });
}

interface IdentifyTraits {
  email: string;
  name?: string;
  avatarUrl?: string;
  emailHash: string;
}

export function identify(userId: string, traits: IdentifyTraits) {
  posthog.identify(userId, traits);

  if (process.env.EQT_PUBLIC_PYLON_APP_ID) {
    window.pylon = {
      chat_settings: {
        app_id: process.env.EQT_PUBLIC_PYLON_APP_ID,

        email: traits.email,
        name: traits.name,
        avatar_url: traits.avatarUrl,
        email_hash: traits.emailHash,
      },
    };
  }

  if (process.env.EQT_PUBLIC_SENTRY_DSN) {
    Sentry.getCurrentScope().setUser({ id: userId, email: traits?.email });
  }
  if (process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY) {
    segment?.identify(userId, traits);
  }
}

export function track(eventName: string, payload?: Record<string, unknown>) {
  posthog.capture(eventName, payload);

  if (process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY) {
    segment?.track(eventName, payload);
  }
}

export function page(pageData?: Record<string, unknown>) {
  posthog.capture('$pageview', pageData);

  if (process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY) {
    segment?.page(pageData);
  }
}

export function group(groupId: string, payload?: Record<string, unknown>) {
  posthog.group('organization', groupId, payload);

  if (process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY) {
    segment?.group(groupId, payload);
  }
}

export function reset() {
  if (process.env.EQT_PUBLIC_SEGMENT_WRITE_KEY) {
    segment?.reset();
  }
}
