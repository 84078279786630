import { current, isDraft } from 'immer';
import deepGet from 'lodash/get';
import deepSet from 'lodash/set';
import { createStore } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { ColorMapping } from '../../api/useReportsApi';
import { useConstant } from '../../utils/useConstant';

interface ColorMappingStoreActions {
  resetColorMapping: (prefixes: string[]) => void;
  registerColor: (keys: string[] | string) => void;
}

export interface ColorMappingStoreValue {
  initialColorMapping: ColorMapping;
  colorMapping: ColorMapping;
  actions: ColorMappingStoreActions;
  onChange: (state: ColorMappingStoreValue) => void;
}

function createActions(
  set: (fn: (state: ColorMappingStoreValue) => void) => void,
): ColorMappingStoreActions {
  return {
    registerColor: (key) => {
      set((state) => {
        let nextIndexOrColor: string | number;
        // If there is a key, we need to sync the value with the chart theme.
        // Step 1. Check if the colorMapping has a value for the key. If yes, return the color at the index.
        // Step 2. Check the initialColorMapping for the key. If yes, return the color at the index.
        // Step 3. If none of the above, register the color for the key and return the color at the index.

        const keys = Array.isArray(key) ? key : [key];

        if (deepGet(state.colorMapping, keys) !== undefined) {
          // Nothing to do here, the color is already set.
          return;
        }

        const fromInitial = deepGet(state.initialColorMapping, keys);
        if (fromInitial !== undefined) {
          nextIndexOrColor = fromInitial;
        } else {
          const parent = deepGet(state.colorMapping, keys.slice(0, -1));
          nextIndexOrColor = parent ? Object.keys(parent).length : 0;
        }

        deepSet(state.colorMapping, keys, nextIndexOrColor);
        state.onChange(state);
      });
    },
    resetColorMapping: (keys: string[]) => {
      set((state) => {
        keys.forEach((key) => {
          deepSet(state.colorMapping, key, undefined);
          deepSet(state.initialColorMapping, key, undefined);
        });
        state.onChange(state);
      });
    },
  };
}

function createChangeHandler(onChange: (colorMapping: ColorMapping) => void) {
  return (state: ColorMappingStoreValue) => {
    if (isDraft(state.colorMapping)) {
      onChange(current(state.colorMapping));
    } else {
      onChange(state.colorMapping);
    }
  };
}

function createColorMappingStore({
  initialColorMapping,
  onChange,
}: {
  initialColorMapping: ColorMapping;
  onChange: (colorMapping: ColorMapping) => void;
}) {
  return createStore<ColorMappingStoreValue>()(
    devtools(
      subscribeWithSelector(
        immer((set) => ({
          initialColorMapping,
          colorMapping: initialColorMapping,
          actions: createActions(set),
          onChange: createChangeHandler(onChange),
        })),
      ),
    ),
  );
}

export function useColorMappingStore({
  initialColorMapping,
  onChange,
}: {
  initialColorMapping: ColorMapping;
  onChange: (colorMapping: ColorMapping) => void;
}) {
  return useConstant(() =>
    createColorMappingStore({ initialColorMapping, onChange }),
  );
}
