import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useApi } from '../components/ApiContext';
import { QueryOptions } from '../utils/types';
import useCurrentOrganizationSlug from '../utils/useCurrentOrganizationSlug';
import useMutationTrigger, {
  UseMutationTriggerConfig,
} from '../utils/useMutationTrigger';

type Features = 'comparisons' | 'defaultDataAccessLevel';

export type CalendarType = 'none' | 'simple' | 'fiscal';

export interface Organization {
  id: string;
  name: string;
  slug: string;
  avatar?: string;
  viewerIsAdmin: boolean;
  viewerDefaultReport: string;
  isActive: boolean;
  isViewingDemoData: boolean;
  hasAcceptedTerms: boolean;
  isSlackEnabled: boolean;
  defaultChartTheme?: import('./useThemesApi').ChartTheme;
  createdAt: string;
  defaultTeam?: { id: string; name: string };
  featureFlags?: Record<Features, boolean>;
  timezone?: string;
  fiscalMonthOffset?: number;
  calendarType?: CalendarType;
  ssoConnectionKey?: string;
  onboardingStatus?:
    | 'not_started'
    | 'in_progress'
    | 'data_integrated'
    | 'completed';
}

export default function useOrganizationApi(
  options: QueryOptions<Organization | undefined> = {},
) {
  const api = useApi();
  const organizationSlug = useCurrentOrganizationSlug();

  return useQuery({
    ...options,
    queryKey: ['organization', organizationSlug],
    queryFn: async () => {
      const orgs = await api.get<Organization[]>('organizations/', {
        slug: organizationSlug,
      });
      return orgs?.[0];
    },
    enabled: !!organizationSlug,
    staleTime: 5 * 60 * 60,
  });
}

export function useOrganizationFeatureFlag<T = boolean>(
  feature: Features,
  defaultValue: boolean | string | T = false,
) {
  const organization = useOrganizationApi();
  return (organization.data?.featureFlags?.[feature] || defaultValue) as T;
}

export function useInvalidateOrganization() {
  const organizationSlug = useCurrentOrganizationSlug();
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['organization', organizationSlug],
      }),
    [queryClient, organizationSlug],
  );
}

export function useUpdateOrganization({
  onSuccess,
  ...options
}: UseMutationTriggerConfig<
  Organization,
  Omit<Partial<Organization>, 'id'> & { id: string }
> = {}) {
  const api = useApi();
  const invalidate = useInvalidateOrganization();

  return useMutationTrigger<
    Organization,
    Omit<Partial<Organization>, 'id'> & { id: string }
  >(
    (data) => {
      return api.put<Organization>(`organizations/${data.id}/`, data);
    },
    {
      ...options,
      successMessage: 'Organization updated',
      onSuccess: (result, queryClient, value) => {
        invalidate();
        return onSuccess?.(result, queryClient, value);
      },
    },
  );
}
