import isEmpty from 'lodash/isEmpty';

import { AnalyticQueryFilter, FilterOperator } from '../../api/query';

export function isValidFilter(filter: AnalyticQueryFilter) {
  if (
    filter.operator === FilterOperator.IS_SET ||
    filter.operator === FilterOperator.IS_NOT_SET
  ) {
    return true;
  }

  // If it's a number, string, or boolean, we can assume it's valid
  if (
    typeof filter.value === 'number' ||
    typeof filter.value === 'string' ||
    typeof filter.value === 'boolean'
  ) {
    return true;
  }

  return !isEmpty(filter.value);
}
