import type { DatasetField, DatasetWithFields } from '@/api/useFields.ts';

export const CURRENT_USER = '$user';
export const DATE_VARIABLE_FIELD: DatasetField = {
  name: '$date',
  field: '$date',
  table: 'variable',
  tableLabel: 'Date',
  label: 'Date',
  valueType: 'timestamp',
  type: 'timestamp',
  fieldType: 'dimension',
  columnType: 'dimension',
  id: '$date',
  dataset: {
    id: 'variable',
    name: 'variable',
    label: 'Date',
  },
};

export class DatasetFieldsHandler {
  datasetsById: Record<string, DatasetWithFields>;
  dimensions: Record<string, DatasetField>;
  measures: Record<string, DatasetField>;
  datasetToAccessibleFields: Record<string, Set<string>>;
  datasetsByCategory: Record<string, DatasetWithFields[]>;

  constructor(datasets: DatasetWithFields[]) {
    this.datasetsById = {};
    this.dimensions = {};
    this.measures = {};
    this.datasetToAccessibleFields = {};
    this.datasetsByCategory = {};

    datasets.forEach((dataset) => {
      this.datasetsById[dataset.id] = dataset;

      dataset.dimensions.forEach((dimension) => {
        this.dimensions[dimension.field] = dimension;
      });

      dataset.measures.forEach((measure) => {
        this.measures[measure.field] = measure;
      });

      const categoryId = dataset.category?.id;
      if (!categoryId) {
        return;
      }

      if (!this.datasetsByCategory[categoryId]) {
        this.datasetsByCategory[categoryId] = [];
      }
      this.datasetsByCategory[categoryId].push(dataset);
    });
  }

  getField(fieldName: string) {
    if (fieldName === DATE_VARIABLE_FIELD.field) {
      return DATE_VARIABLE_FIELD;
    }

    return this.dimensions[fieldName] || this.measures[fieldName];
  }

  getDataset(datasetId: string | null | undefined) {
    if (!datasetId) {
      return;
    }

    return this.datasetsById[datasetId];
  }

  getAccessibleFields(datasetId: string, type: 'filter' | 'breakdown') {
    const filterType = (item: DatasetField) =>
      type === 'filter' ? item.isFilter : item.isBreakdown;

    if (this.datasetToAccessibleFields[datasetId]) {
      return Array.from(this.datasetToAccessibleFields[datasetId])
        .map((fieldName) => this.getField(fieldName))
        .filter(filterType);
    }

    const dataset = this.getDataset(datasetId);
    if (!dataset) {
      return [];
    }

    const fields = [...dataset.dimensions];
    dataset.accessibleDatasets.forEach((accessibleDatasetId) => {
      const accessibleDataset = this.getDataset(accessibleDatasetId);
      if (!accessibleDataset) {
        return;
      }

      fields.push(...accessibleDataset.dimensions);
    });

    this.datasetToAccessibleFields[datasetId] = new Set(
      fields.map((field) => field.field),
    );

    return fields.filter(filterType);
  }
}
