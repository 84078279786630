import { useAuth0 } from '@auth0/auth0-react';
import { store } from '@eqtble/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { Organization } from '../api/useOrganizationApi';

const STORAGE_KEY = '@@eqtble/current-organization';

export default function useCurrentOrganizationSlug(
  validateOrganization = true,
) {
  const { organizationSlug } = useParams();
  const auth = useAuth0();
  const queryClient = useQueryClient();

  const user = queryClient.getQueryData<{
    organizations?: { slug: string }[];
  } | null>(['me']);

  useEffect(() => {
    if (!validateOrganization || !organizationSlug) {
      // At some point there is no information in query path with current organization
      // so we need just to use value from local storage and do validate it
      return;
    }

    // XXX: assumed that the organizations API has been called at some point
    try {
      const isValidOrg = queryClient.getQueryData([
        'organization',
        organizationSlug,
      ]);

      if (!isValidOrg) {
        store.remove(STORAGE_KEY);
      } else {
        store.set(STORAGE_KEY, organizationSlug);
      }
    } catch (e) {
      store.remove(STORAGE_KEY);
    }
  }, [organizationSlug, queryClient, validateOrganization]);

  return useMemo(() => {
    if (!auth.isAuthenticated) {
      return null;
    }

    if (organizationSlug) {
      return organizationSlug;
    }

    const storedOrganizationSlug = store.get<string>(STORAGE_KEY);
    if (storedOrganizationSlug) {
      return storedOrganizationSlug;
    }

    // If there's no organization slug in the URL, and no organization slug in
    // local storage, then we attempt to get the first organization the user is
    // a member of.

    if (!user) {
      return null;
    }

    if (user?.organizations?.length) {
      return user.organizations[0].slug;
    }

    return null;
  }, [auth, organizationSlug, user]);
}

export function useCurrentOrganization() {
  const organizationSlug = useCurrentOrganizationSlug();
  const queryClient = useQueryClient();
  try {
    return queryClient.getQueryData<Organization>([
      'organization',
      organizationSlug,
    ]);
  } catch (e) {
    return null;
  }
}
