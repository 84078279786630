import { deepMapToCamelCase } from './getPayloadData';

export default function getResponseData<T = Record<any, any>>(
  data?: T,
  whitelistKeys: string[] = [],
) {
  if (!data) {
    return null;
  }
  return deepMapToCamelCase(data, whitelistKeys) as T | null;
}
