import { defineMessages } from 'react-intl';

export const globals = defineMessages({
  helpCenter: {
    id: 'globals.helpCenter',
    defaultMessage: 'Help center',
  },
  all: {
    id: 'globals.all',
    defaultMessage: 'All',
  },
  label: {
    id: 'globals.label',
    defaultMessage: 'Label',
  },
  description: {
    id: 'globals.description',
    defaultMessage: 'Description',
  },
  email: {
    id: 'globals.email',
    defaultMessage: 'Email',
  },
  currentUser: {
    id: 'globals.currentUser',
    defaultMessage: 'Current user',
  },
  currentUserDescription: {
    id: 'globals.currentUserDescription',
    defaultMessage: "The logged-in user's email that is viewing the story",
  },
  subscribers: {
    id: 'globals.subscribers',
    defaultMessage: 'Subscribers',
  },
  slack: {
    id: 'globals.slack',
    defaultMessage: 'Slack',
  },
  name: {
    id: 'globals.name',
    defaultMessage: 'Name',
    description: 'Name of the entity',
  },
  fullName: {
    id: 'globals.fullName',
    defaultMessage: 'Full name',
    description: 'Full name of the user',
  },
  lastUpdated: {
    id: 'globals.lastUpdated',
    defaultMessage: 'Last updated',
  },
  updatedAt: {
    id: 'globals.updatedAt',
    defaultMessage: 'Updated {date}',
  },
  csv: {
    id: 'globals.csv',
    defaultMessage: 'CSV',
  },
  image: {
    id: 'globals.image',
    defaultMessage: 'Image',
  },
  filters: {
    id: 'globals.filters',
    defaultMessage: 'Filters',
  },
  noValuesFound: {
    id: 'globals.noValuesFound',
    defaultMessage: 'No values found',
  },
  loading: {
    id: 'globals.loading',
    defaultMessage: 'Loading...',
  },
  copied: {
    id: 'globals.copied',
    defaultMessage: 'Copied!',
  },
  error: {
    id: 'globals.error',
    defaultMessage: 'Error',
  },
  saved: {
    id: 'globals.saved',
    defaultMessage: 'Saved!',
  },
  active: {
    id: 'globals.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'globals.inactive',
    defaultMessage: 'Inactive',
  },
  default: {
    id: 'globals.default',
    defaultMessage: 'Default',
  },
  pdf: {
    id: 'globals.pdf',
    defaultMessage: 'PDF',
  },
  powerpoint: {
    id: 'globals.powerpoint',
    defaultMessage: 'PowerPoint',
  },
  notificationTarget: {
    id: 'globals.notificationTarget',
    defaultMessage: 'Notification type',
  },
  itemType: {
    id: 'globals.itemType',
    defaultMessage: 'Item type',
  },
  user: {
    id: 'globals.user',
    defaultMessage: 'User',
  },
  channel: {
    id: 'globals.channel',
    defaultMessage: 'Channel',
  },
  advancedSettings: {
    id: 'globals.advancedSettings',
    defaultMessage: 'Advanced settings',
  },
  by: {
    id: 'globals.by',
    defaultMessage: 'by',
  },
  byValue: {
    id: 'globals.byValue',
    defaultMessage: 'by {value}',
  },
  and: {
    id: 'globals.and',
    defaultMessage: 'and',
  },
  createdBy: {
    id: 'globals.createdBy',
    defaultMessage: 'Created by',
  },
  recentlyViewedBy: {
    id: 'globals.recentlyViewedBy',
    defaultMessage: 'Recently viewed by',
  },
  members: {
    id: 'globals.members',
    defaultMessage: 'Members',
  },
});
