import {
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import type { IntlShape, MessageDescriptor } from 'react-intl';

import { getLastRangePresetValue } from './getLastRangePresetValue';
import { messages } from './i18n';
import { DateRangePickerValue, Interval, isFixedRangeValue } from './types';
import { isLastRangeValue, isPreset, isSinceRangeValue } from './types';
import { AnalyticQueryFilter, FilterOperator } from '../../api/query';
import {
  formatDate,
  getGranularityFromValue,
  parseDate,
  parseGranularityDate,
} from '../../utils/dates';

const MIN_DATE = new Date(0);

export function calculateDateRange({
  interval,
  value,
  completedOnly,
}: {
  interval: Interval;
  value: number;
  completedOnly?: boolean | null | undefined;
}): [Date, Date] | null {
  if (interval === 'days') {
    // TODAY is 07/26/2023
    // INTERVAL VALUE is 7
    // EXPECTED RANGE: [07/20/2023 (start), 07/26/2023 (end)]

    // RANGE START:
    // subDays(startOfToday(), 6) --> 07/20/2023
    // startOfDay(07/20/2023) --> 07/20/2023
    // RANGE END:
    // endOfToday() --> 07/26/2023

    // COMPLETE ONLY:
    // EXPECTED RANGE: [07/20/2023 (start), 07/25/2023 (end)]
    const start = completedOnly ? subDays(startOfToday(), 1) : startOfToday();
    const end = completedOnly ? endOfYesterday() : endOfToday();
    return [startOfDay(subDays(start, value - 1)), end];
  }
  if (interval === 'weeks') {
    // TODAY is 07/26/2023
    // INTERVAL VALUE is 4
    // EXPECTED RANGE: [07/03/2023 (Monday, start of day), 07/30/2023 (Sunday, end of day)]

    // RANGE START:
    // subWeeks(startOfToday(), 3) --> 07/05/2023
    // startOfWeek(07/05/2023) --> 07/04/2023
    // RANGE END:
    // endOfToday() --> 07/26/2023
    // endOfWeek(07/26/2023) --> 07/30/2023

    // COMPLETE ONLY:
    // EXPECTED RANGE: [06/26/2023 (Monday, start of day), 07/23/2023 (Sunday, end of day)]

    const start = completedOnly ? subWeeks(startOfToday(), 1) : startOfToday();
    const end = completedOnly ? subWeeks(endOfToday(), 1) : endOfToday();
    return [
      startOfWeek(subWeeks(start, value - 1), {
        weekStartsOn: 1, // Monday
      }),
      endOfWeek(end, {
        weekStartsOn: 1, // Monday
      }),
    ];
  }
  if (interval === 'months') {
    // TODAY is 07/26/2023
    // INTERVAL VALUE is 3
    // EXPECTED RANGE: [05/01/2023 (start of month), 07/31/2023 (end of month)]

    // RANGE START:
    // subMonths(startOfToday(), 2) --> 05/26/2023
    // startOfMonth(04/26/2023) --> 05/01/2023
    // RANGE END:
    // endOfToday() --> 07/26/2023
    // endOfMonth(07/26/2023) --> 07/31/2023

    // COMPLETE ONLY:
    // EXPECTED RANGE: [04/01/2023 (start of month), 06/30/2023 (end of month)]

    const start = completedOnly ? subMonths(startOfToday(), 1) : startOfToday();
    const end = completedOnly ? subMonths(endOfToday(), 1) : endOfToday();
    return [startOfMonth(subMonths(start, value - 1)), endOfMonth(end)];
  }
  if (interval === 'quarters') {
    // TODAY is 07/26/2023 (Q3)
    // INTERVAL VALUE is 2 (Q2-Q3)
    // EXPECTED RANGE: [04/01/2023 (start of quarter), 09/30/2023 (end of quarter)]

    // RANGE START:
    // subQuarters(startOfToday(), 1) --> 04/26/2023
    // startOfQuarter(04/26/2023) --> 04/01/2023
    // RANGE END:
    // endOfToday() --> 07/26/2023
    // endOfQuarter(07/26/2023) --> 09/30/2023

    // COMPLETE ONLY:
    // EXPECTED RANGE: [01/01/2023 (start of quarter), 06/30/2023 (end of quarter)]

    const start = completedOnly
      ? subQuarters(startOfToday(), 1)
      : startOfToday();
    const end = completedOnly ? subQuarters(endOfToday(), 1) : endOfToday();
    return [startOfQuarter(subQuarters(start, value - 1)), endOfQuarter(end)];
  }
  if (interval === 'years') {
    // TODAY is 07/26/2023
    // INTERVAL VALUE is 1
    // EXPECTED RANGE: [01/01/2023 (start of year), 12/31/2023 (end of year)]

    // RANGE START:
    // subYears(startOfToday(), 0) --> 07/26/2023
    // startOfYear(07/26/2023) --> 01/01/2023
    // RANGE END:
    // endOfToday() --> 07/26/2023
    // endOfYear(07/26/2023) --> 12/31/2023

    // COMPLETE ONLY:
    // EXPECTED RANGE: [01/01/2022 (start of year), 12/31/2022 (end of year)]

    const start = completedOnly ? subYears(startOfToday(), 1) : startOfToday();
    const end = completedOnly ? subYears(endOfToday(), 1) : endOfToday();
    return [startOfYear(subYears(start, value - 1)), endOfYear(end)];
  }

  return null;
}

export type PresetValue = {
  value: DateRangePickerValue;
  label?: MessageDescriptor | string;
};

export const DEFAULT_PRESET_VALUES: PresetValue[] = [
  {
    label: messages.today,
    value: 'today',
  },
  {
    label: messages.yesterday,
    value: 'yesterday',
  },
  {
    label: messages.last7Days,
    value: 'last 7 days',
  },
  {
    label: messages.last30Days,
    value: 'last 30 days',
  },
  {
    label: messages.last3Months,
    value: 'last 3 months',
  },
  {
    label: messages.last6Months,
    value: 'last 6 months',
  },
  {
    label: messages.last12Months,
    value: 'last 12 months',
  },
];

interface PresetMatcher {
  regex: RegExp;
  parse: (match: RegExpMatchArray) => DateRangePickerValue;
  label: (match: RegExpMatchArray, intl: IntlShape) => string;
  operator: (match: RegExpMatchArray) => FilterOperator;
}

// Below matches the Python Regex for the preset matching.
// All we're doing here is just confirming that the text matches the regex

function matchGranularity(match: RegExpMatchArray) {
  if (!match.groups) {
    return null;
  }

  const { completed, completed2, value, fiscal, grain } = match.groups;

  let interval = `${grain}s` as Interval;
  if (fiscal) {
    interval = `fiscal_${interval}` as Interval;
  }

  const completedOnly = completed === 'completed' || completed2 === 'completed';
  const parsedValue = Number.parseInt(value, 10);

  return {
    interval,
    value: parsedValue,
    completedOnly,
  };
}

const FISCAL_MONTH_REGEX = /^M(?<month>\d{1,2}) FY(?<year>\d{4})$/i;
const FISCAL_QUARTER_REGEX = /^Q(?<quarter>[1-4]) FY(?<year>\d{4})$/i;
const FISCAL_YEAR_REGEX = /^FY(?<year>\d{4})$/i;

function isFiscalDate(fiscalDate: string) {
  return (
    FISCAL_MONTH_REGEX.test(fiscalDate) ||
    FISCAL_QUARTER_REGEX.test(fiscalDate) ||
    FISCAL_YEAR_REGEX.test(fiscalDate)
  );
}

function matchRange(match: RegExpMatchArray): DateRangePickerValue {
  if (!match.groups) {
    return null;
  }

  const { start, end } = match.groups;
  // First we check to see if the date is a fiscal date
  // If yes, then we return the original string and leave it to the backend to parse
  if (isFiscalDate(start) && isFiscalDate(end)) {
    return `${start} to ${end}`;
  }

  const granularity = getGranularityFromValue(start);

  if (!granularity) {
    // If the granularity is not found, then we parse the date
    const parsedStart = parseDate(start);
    const parsedEnd = parseDate(end);

    if (!parsedStart || !parsedEnd) {
      return null;
    }

    return [parsedStart.toISOString(), parsedEnd.toISOString()] as const;
  }

  const parsedStart = parseGranularityDate(start, granularity);
  const parsedEnd = parseGranularityDate(end, granularity);

  if (!parsedStart || !parsedEnd) {
    return null;
  }

  return [parsedStart.toISOString(), parsedEnd.toISOString()] as const;
}

const PRESET_MATCHERS: PresetMatcher[] = [
  {
    // Match today or yesterday
    regex: /^today|yesterday$/i,
    operator: () => FilterOperator.BETWEEN,
    parse: (match) => {
      const matched = match[0].toLowerCase();
      const completedOnly = matched === 'yesterday';
      return {
        interval: 'days',
        value: 1,
        completedOnly,
      };
    },
    label: (match, intl) => {
      const value = match[0].toLowerCase();

      if (value === 'today') {
        return intl.formatMessage(messages.today);
      }
      if (value === 'yesterday') {
        return intl.formatMessage(messages.yesterday);
      }

      return '';
    },
  },
  {
    // Match last 7, 30, 90, 180, 365 days
    regex:
      /(?<completed>completed)?[-_ ]*?(?<value>\d+)[-_ ]*?(?<completed2>completed)?[-_ ]*?(?<fiscal>fiscal)?[-_ ]*?(?<grain>day|week|month|quarter|year)s?$/i,
    parse: (match) => {
      return matchGranularity(match);
    },
    label: (match, intl) => {
      const matched = matchGranularity(match);
      if (!matched) {
        return '';
      }

      const { value, interval, completedOnly } = matched;

      const message = completedOnly
        ? messages.lastCompletedLabel
        : messages.lastLabel;
      const intervalMessage = intl.formatMessage(messages[interval]);
      return intl.formatMessage(message, {
        value,
        interval: intervalMessage,
      });
    },
    operator: (match) => {
      const matched = matchGranularity(match);
      if (!matched) {
        return FilterOperator.BETWEEN;
      }

      const { completedOnly } = matched;

      return completedOnly ? FilterOperator.LESS_THAN : FilterOperator.BETWEEN;
    },
  },
  {
    // Match fixed date range
    regex: /^(?<start>.*) to (?<end>.*)$/i,
    parse: (match) => {
      return matchRange(match);
    },
    label: (match, intl) => {
      const matched = matchRange(match);
      if (!matched || !match.groups) {
        return '';
      }

      if (typeof matched === 'string') {
        return matched;
      }

      if (!isFixedRangeValue(matched)) {
        return '';
      }

      const { start, end } = match.groups;
      return intl.formatMessage(messages.fixedLabel, {
        startDate: start,
        endDate: end,
      });
    },
    operator: () => FilterOperator.BETWEEN,
  },
  {
    // Match last day, week, month, quarter, year
    regex: /^this[-_ ]*?(?<grain>day|week|month|quarter|year)s?$/i,
    operator: () => FilterOperator.BETWEEN,
    parse: (match) => {
      if (!match.groups) {
        return null;
      }

      const { grain } = match.groups;

      const interval = `${grain}s` as Interval;
      const range = calculateDateRange({ interval, value: 1 });

      if (!range) {
        return null;
      }

      return range.map((date) => date.toISOString()) as DateRangePickerValue;
    },
    label: (match, intl) => {
      if (!match.groups) {
        return '';
      }

      const { grain } = match.groups;

      const interval = `${grain}s` as Interval;
      const intervalMessage = intl.formatMessage(messages[interval]);
      return intl.formatMessage(messages.thisIntervalLabel, {
        interval: intervalMessage,
      });
    },
  },
  {
    // After <date>, before <date>
    regex: /^(?<modifier>after|before|since)?[-_ ]?(?<date>.*)$/i,
    parse: (match) => {
      if (!match.groups) {
        return null;
      }

      const { date } = match.groups;
      const parsedDate = parseDate(date);

      if (!parsedDate) {
        return null;
      }

      const modifier = match.groups?.modifier?.toLowerCase();
      if (modifier === 'before') {
        return [MIN_DATE.toISOString(), parsedDate.toISOString()] as const;
      }

      return [parsedDate.toISOString()] as const;
    },
    label: (match, intl) => {
      if (!match.groups) {
        return '';
      }

      const { date } = match.groups;
      const parsedDate = parseDate(date);

      if (!parsedDate) {
        return '';
      }

      const modifier = match.groups?.modifier?.toLowerCase();

      if (!modifier) {
        return intl.formatMessage(messages.onLabel, {
          value: formatDate(parsedDate),
        });
      }

      if (modifier === 'before') {
        return intl.formatMessage(messages.beforeLabel, {
          value: formatDate(parsedDate),
        });
      }

      return intl.formatMessage(messages.sinceLabel, {
        value: formatDate(parsedDate),
      });
    },
    operator: (match) => {
      if (!match.groups) {
        return FilterOperator.BETWEEN;
      }

      const modifier = match.groups?.modifier?.toLowerCase();

      if (!modifier) {
        return FilterOperator.EQUALS;
      }

      if (modifier === 'before') {
        return FilterOperator.LESS_THAN_EQUALS;
      }

      // after, since
      return FilterOperator.GREATER_THAN_EQUALS;
    },
  },
];

/**
 * Confirm that the text matches the regex for a valid date range preset
 * and return the matcher and the regex match.
 * @param text
 */
export function matchPreset(
  text: string,
): [PresetMatcher, RegExpMatchArray] | null {
  for (const matcher of PRESET_MATCHERS) {
    const match = text.match(matcher.regex);
    if (match) {
      return [matcher, match];
    }
  }

  return null;
}

export function getDateRangeOrPreset(
  value: DateRangePickerValue | null | undefined,
): Pick<AnalyticQueryFilter, 'value' | 'operator'> | null {
  if (value == null) {
    return null;
  }

  let interval: Interval | null = null;
  let intervalValue: number | null = null;
  let completedOnly: boolean | null = null;
  let operator: FilterOperator | null = null;

  if (isPreset(value)) {
    const match = matchPreset(value);

    if (!match) {
      // If there's no match, return the preset string
      return { value, operator: FilterOperator.BETWEEN };
    }

    const [matcher, regexMatch] = match;

    // If the value is a default preset then return the value
    if (DEFAULT_PRESET_VALUES.some((preset) => preset.value === value)) {
      return { value, operator: FilterOperator.BETWEEN };
    }

    const parsed = matcher.parse(regexMatch);

    if (!parsed) {
      return null;
    }

    operator = matcher.operator(regexMatch);
    if (isLastRangeValue(parsed)) {
      interval = parsed.interval;
      intervalValue = parsed.value;
      completedOnly = parsed.completedOnly ?? null;
    } else {
      return { operator, value: parsed };
    }
  }

  if (isLastRangeValue(value)) {
    interval = value.interval;
    intervalValue = value.value;
    completedOnly = value.completedOnly ?? null;
  }

  if (interval && intervalValue) {
    const formattedValue = getLastRangePresetValue({
      interval,
      value: intervalValue,
      completedOnly,
    });

    let resolvedOperator: FilterOperator;
    if (operator) {
      resolvedOperator = operator;
    } else if (completedOnly) {
      resolvedOperator = FilterOperator.LESS_THAN;
    } else {
      resolvedOperator = FilterOperator.BETWEEN;
    }

    return {
      value: formattedValue,
      operator: resolvedOperator,
    };
  }

  if (!Array.isArray(value)) {
    return null;
  }

  if (isSinceRangeValue(value)) {
    return {
      value,
      operator: FilterOperator.GREATER_THAN_EQUALS,
    };
  }

  return {
    value,
    operator: FilterOperator.BETWEEN,
  };
}
