import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
} from '@eqtble/ui';
import cx from 'clsx';
import { useId } from 'react';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form';

import FieldError from './FieldError';

interface FieldGroupProps<TFieldErrors extends FieldValues>
  extends Omit<FormControlProps, 'label'> {
  /** An `name` to used to associate the label and input. */
  name: FieldName<TFieldErrors>;
  children: React.ReactNode;
  label?: React.ReactNode;
  /** Hides label while maintaining the accessibility of the form field. */
  labelSrOnly?: boolean;
  labelClassName?: string;
  description?: string;
  className?: string;
  required?: boolean;
  errors?: FieldErrors<TFieldErrors>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-testid'?: string;
}

function FieldGroup<TFieldErrors extends FieldValues>({
  name,
  children,
  className,
  description,
  label,
  labelClassName,
  labelSrOnly,
  'data-testid': dataCy,
  errors,
  ...props
}: FieldGroupProps<TFieldErrors>) {
  const controlId = useId();
  const id = name ?? controlId;

  return (
    <FormControl
      {...props}
      id={id}
      isInvalid={!!errors?.[name]}
      className={className}
      data-testid={dataCy && `${dataCy}-group`}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          className={cx(labelSrOnly && 'sr-only', labelClassName)}
          data-testid={dataCy && `${dataCy}-label`}
        >
          {label}
        </FormLabel>
      )}
      {children}
      {description && (
        <FormHelperText data-testid={dataCy && `${dataCy}-description`}>
          {description}
        </FormHelperText>
      )}
      <FieldError
        name={name as any}
        data-testid={dataCy && `${dataCy}-error`}
        errors={errors}
      />
    </FormControl>
  );
}

export default FieldGroup;
