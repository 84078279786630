import { createContext, store, useLocalStorage } from '@eqtble/utils';
import { useCallback, useMemo } from 'react';

const THEME_KEY = '@@eqtble/theme';
type Theme = 'light' | 'dark';

const BACKGROUND_COLOR = {
  light: '#fff',
  dark: '#1d2025',
};

interface ThemeContextValue {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  backgroundColor: string;
}

const [ThemeContextProvider, useTheme] = createContext<ThemeContextValue>({
  name: 'ThemeContext',
  strict: false,
});

// on initial load make sure that the theme is set from localStorage to the body class
export function toggleTheme(theme: Theme = store.get(THEME_KEY) as Theme) {
  document.documentElement.classList.remove('dark', 'light');
  document.documentElement.classList.add(theme);
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setTheme] = useLocalStorage<Theme>(THEME_KEY, 'light');

  const handleThemeChange = useCallback(
    (nextTheme: Theme) => {
      toggleTheme(nextTheme);
      setTheme(nextTheme);
    },
    [setTheme],
  );

  const value = useMemo(
    () => ({
      theme,
      setTheme: handleThemeChange,
      backgroundColor: BACKGROUND_COLOR[theme],
    }),
    [theme, handleThemeChange],
  );

  return <ThemeContextProvider value={value}>{children}</ThemeContextProvider>;
}

export { ThemeProvider, useTheme };
