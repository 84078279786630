import { useState } from 'react';

function useDisclosure(defaultIsOpen?: boolean) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const onToggle = () => setIsOpen((prevIsOpen) => !prevIsOpen);
  return { isOpen, onOpen, onClose, onToggle };
}

export default useDisclosure;
