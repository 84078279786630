/* eslint-disable @typescript-eslint/naming-convention */
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useGetPath } from './apiFactory';
import { AnalyticQuery, cleanQuery } from './useAnalyticsQuery';
import { useApi } from '../components/ApiContext';
import { setSelectedBlockInUrl } from '../components/Editor/useReportBlocks';
import { VizConfig } from '../components/Query/VizConfig';
import { VizType } from '../components/Query/VizType';
import { clearReportEditCache } from '../pages/stories/components/useStoryFormCache';
import useCurrentOrganizationSlug from '../utils/useCurrentOrganizationSlug';
import useMutationTrigger from '../utils/useMutationTrigger';

const REPORTS_API_ENDPOINT = 'reports';
const BLOCKS_API_ACTION = 'blocks';
const WHITELIST_KEYS = ['data', 'column_sizing', 'columnSizing'];

export type BlockType =
  | 'text'
  | 'divider'
  | 'query'
  | 'media'
  | 'quarto'
  | 'embed'
  | 'ai'; // AI block is only used on the frontend

export type TextContent = {
  text?: string;
  type: 'text';
  marks?: { type: 'bold' | 'italic' }[];
};

export type KpiContent = {
  type: 'kpi';
  attrs: { query: Partial<Query> };
};

export type TextData<TContent = TextContent | KpiContent> =
  | {
      type: 'doc';
      content: TextData[];
    }
  | {
      type: 'heading';
      attrs?: {
        level?: 1 | 2 | 3;
      };
      content: TContent[];
    }
  | {
      type: 'paragraph';
      content?: TContent[];
    };

export type MediaData = {
  video?: string;
  image?: {
    imageUrl: string;
    id: string;
  };
};

export interface QuartoData {
  url?: string;
  file?: {
    id: string;
    file: string;
  };
}

export interface EmbedData {
  url: string;
}

export interface AiData {
  query: string;
  result: Query;
}

export interface Query {
  id?: string;
  title: string;
  description?: string;
  timestamp?: string | null | undefined;
  data: AnalyticQuery;
  vizType: VizType;
  vizConfig: VizConfig;
}

export function isValidQuery(query: Partial<Query> | null | undefined) {
  if (!query) {
    return false;
  }

  const { data, vizType, vizConfig } = query;

  if (!data || !vizType || !vizConfig || !data.fields) {
    return false;
  }

  return data.fields.length > 0;
}

export interface ReportBlockLayout {
  x: number;
  y: number;
  w: number;
  h: number;
  minH?: number;
  i?: string;
}

export type ReportBlockAPI = {
  id: string;
  blockType: BlockType;
  data: TextData | Record<string, never>;
  layout: ReportBlockLayout;
  image: string | null;
  imageUrl: string | null;
  query: Query | null;
  quarto: string;
};

export type ReportBlockPayloadAPI = {
  id?: string;
  blockType: BlockType;
  data: TextData | Record<string, never>;
  layout: { x: number; y: number; w: number; h: number };
  insight: string | null;
  image: string | null;
  query: Query | null;
};

type DividerData = never;

type ReportBlockData =
  | TextData
  | DividerData
  | Partial<Query>
  | MediaData
  | QuartoData
  | AiData; // AI block is only used on the frontend;

export type ReportBlockEditor = {
  id: string;
  type: BlockType;
  // For insight there is { insightId: INSIGHT_WITH_KPI_AND_CHART }
  data: ReportBlockData;
  layout: ReportBlockLayout;

  // Indicates if the block is new and not yet saved to the server
  __isNew?: boolean;
};

function useReportBlockQueryKey() {
  const organizationSlug = useCurrentOrganizationSlug();
  return (reportId: string | undefined, id?: string): QueryKey =>
    [
      organizationSlug,
      REPORTS_API_ENDPOINT,
      reportId,
      BLOCKS_API_ACTION,
      id,
    ].filter(Boolean);
}

export function useInvalidateReportBlocks() {
  const queryClient = useQueryClient();
  const getQueryKey = useReportBlockQueryKey();
  return (reportId: string) => {
    queryClient.removeQueries({ queryKey: getQueryKey(reportId) });
    return queryClient.invalidateQueries({ queryKey: [getQueryKey(reportId)] });
  };
}

export function useUpdateReportBlocksCache() {
  const queryClient = useQueryClient();
  const getQueryKey = useReportBlockQueryKey();
  return (reportId: string, blocks: ReportBlockEditor[]) => {
    const queryKey = getQueryKey(reportId);
    queryClient.setQueryData(queryKey, blocks);
  };
}

export function toReportBlockEditor(block: ReportBlockAPI) {
  const {
    id,
    blockType,
    data,
    image,
    imageUrl,
    query: blockQuery,
    layout,
  } = block;
  let reportData: TextData | Partial<Query> | MediaData;
  if (blockQuery) {
    reportData = {
      ...(data as Partial<Query>),
      ...blockQuery,
    };
  } else if (image && imageUrl) {
    reportData = {
      ...(data as Partial<MediaData>),
      image: {
        id: image,
        imageUrl,
      },
    };
  } else {
    reportData = data as TextData;
  }

  return {
    id,
    layout,
    type: blockType,
    data: reportData,
  };
}

export function useReportBlocksApi(
  reportId: string | undefined,
  options: Omit<UseQueryOptions<ReportBlockEditor[] | null>, 'queryKey'> = {},
) {
  const api = useApi();
  const getBasePath = useGetPath(
    'url',
    `${REPORTS_API_ENDPOINT}/${reportId}/${BLOCKS_API_ACTION}`,
  );

  const getQueryKey = useReportBlockQueryKey();
  const query = useQuery({
    ...options,
    queryKey: getQueryKey(reportId),
    queryFn: async () => {
      const reportBlocks = await api.get<ReportBlockAPI[]>(
        getBasePath(),
        {},
        WHITELIST_KEYS,
      );

      if (!reportBlocks) {
        return [] as ReportBlockEditor[];
      }

      return reportBlocks
        .sort((a, b) => a.layout.y - b.layout.y)
        .map(toReportBlockEditor);
    },
    enabled: !!reportId,
  });

  return { ...query, isLoading: !reportId ? false : query.isLoading };
}

export function cleanBlock(
  block: ReportBlockEditor,
): ReportBlockPayloadAPI | null {
  const { id, type, data, layout } = block;

  if (type === 'ai') {
    return null;
  }

  const {
    insightId: insight = null,
    image = null,
    ...cleanData
  } = (data ?? {}) as any;

  const cleanLayout = {
    x: layout.x,
    y: layout.y,
    w: layout.w,
    h: layout.h,
  };

  const result: Partial<ReportBlockPayloadAPI> = {
    id,
    blockType: type,
    layout: cleanLayout,
    insight,
    image: image?.id ?? null,
  };

  if (type === 'query') {
    const query = cleanData as Query;
    query.data = cleanQuery(query.data)!;
    result.query = query;
  } else {
    result.data = cleanData as TextData;
  }

  return result as ReportBlockPayloadAPI;
}

function useGetReportBlocksUrl() {
  const getBasePath = useGetPath('url', REPORTS_API_ENDPOINT);
  return (reportId: string) => `${getBasePath(reportId)}blocks/`;
}

function useCreateBlock() {
  const api = useApi();
  const navigate = useNavigate();
  const organizationSlug = useCurrentOrganizationSlug();
  const getBasePath = useGetPath('url', REPORTS_API_ENDPOINT);
  const invalidate = useInvalidateReportBlocks();
  const getReportBlocksUrl = useGetReportBlocksUrl();

  return useMutationTrigger(
    async ({
      reportId,
      block,
    }: {
      reportId?: string;
      block: ReportBlockEditor;
    }) => {
      let finalReportId: string | undefined = reportId;
      let blockId: string | undefined;

      const cleanedBlock = cleanBlock(block);

      if (!cleanedBlock) {
        return;
      }

      if (reportId) {
        const result = await api.post<ReportBlockPayloadAPI>(
          getReportBlocksUrl(reportId),
          cleanedBlock,
          undefined,
          WHITELIST_KEYS,
        );
        if (!result) {
          return;
        }

        await invalidate(reportId);
        clearReportEditCache(reportId);
        blockId = result.id;
      } else {
        const newReport = await api.post<Record<string, unknown>>(
          getBasePath(),
          {
            title: 'Untitled',
            blocks: [cleanedBlock],
          },
        );
        if (!newReport) {
          return;
        }

        finalReportId = newReport.id as string;
        blockId = (newReport.blocks as any)[0].id;
      }

      return navigate(
        `/${organizationSlug}/stories/${finalReportId}?selectedBlock=${blockId}`,
      );
    },
    {},
  );
}

export function useGenerateQueryBlock() {
  return (query: Partial<Query>): ReportBlockEditor => ({
    id: uuidv4(),
    type: 'query' as BlockType,
    data: query,
    layout: {
      x: 0,
      y: 0,
      w: 4,
      h: 500,
    },
  });
}

export function useAddBlockToStory() {
  const api = useApi();
  const getReportBlocksUrl = useGetReportBlocksUrl();
  const [handleCreateBlock, props] = useCreateBlock();

  const addBlockToStory = useCallback(
    async ({
      reportId,
      block,
    }: {
      reportId: string;
      block: ReportBlockEditor;
    }) => {
      const blocks = await api.get<ReportBlockAPI[]>(
        getReportBlocksUrl(reportId),
      );

      let maxHeight = 0;
      if (blocks && blocks.length > 0) {
        maxHeight = Math.max(...blocks.map((b) => b.layout.y));
      }

      // add selected block to existing story
      setSelectedBlockInUrl(block.id);
      // get the report and put the block at the end
      return handleCreateBlock({
        reportId,
        block: {
          ...block,
          layout: {
            ...block.layout,
            y: maxHeight + 1,
          },
        },
      });
    },
    [api, getReportBlocksUrl, handleCreateBlock],
  );

  return [addBlockToStory, props] as const;
}

export function useCreateReportWithBlock() {
  const [handleCreateBlock, props] = useCreateBlock();

  const createReportWithBlock = useCallback(
    (block: ReportBlockEditor) => {
      setSelectedBlockInUrl(block.id);
      return handleCreateBlock({ block });
    },
    [handleCreateBlock],
  );

  return [createReportWithBlock, props] as const;
}
