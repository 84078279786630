import noop from 'lodash/noop';
import { useContext } from 'react';
import React from 'react';

import { DateRangePickerValue } from '../../DateRangePicker';
export const EFFECTIVE_DATE_KEY = 'effective_date';
export const EFFECTIVE_DATE_START_KEY = 'effective_date_start';
export const EFFECTIVE_DATE_END_KEY = 'effective_date_end';
export const DEFAULT_EFFECTIVE_DATE = 'last 12 months';

const DATE_GRANULARITY_KEY = 'date_granularity';
const DEFAULT_DATE_GRANULARITY = 'Month';

const DIMENSION_GRANULARITY_KEY = 'dimension_granularity';

const GRANULARITY_FILTERS = [DATE_GRANULARITY_KEY, DIMENSION_GRANULARITY_KEY];

const EFFECTIVE_DATE_KEYS = [
  EFFECTIVE_DATE_KEY,
  EFFECTIVE_DATE_START_KEY,
  EFFECTIVE_DATE_END_KEY,
];

export const ACTIONABLE_SUMMARY_FILTERS = [
  ...EFFECTIVE_DATE_KEYS,
  ...GRANULARITY_FILTERS,
];

export type ActionableSummaryFilterKey =
  (typeof ACTIONABLE_SUMMARY_FILTERS)[number];

export interface FilterActionsValue {
  [EFFECTIVE_DATE_KEY]: DateRangePickerValue | null | undefined;
  [DATE_GRANULARITY_KEY]: string | null | undefined;
  [DIMENSION_GRANULARITY_KEY]: string | null | undefined;
}

export const DEFAULT_FILTER_ACTIONS_VALUE: FilterActionsValue = {
  [EFFECTIVE_DATE_KEY]: DEFAULT_EFFECTIVE_DATE,
  [DATE_GRANULARITY_KEY]: DEFAULT_DATE_GRANULARITY,
  [DIMENSION_GRANULARITY_KEY]: undefined,
};

export function isActionableSummaryFilter(
  filterName: string,
): filterName is ActionableSummaryFilterKey {
  // We still need to use the old summary filters to display
  // actions -- such as the effective date filter, date granularity, and dimension groupings.
  return ACTIONABLE_SUMMARY_FILTERS.includes(filterName);
}

interface FilterActionsContextValue {
  filterActions: Set<string> | null | undefined;
  value: FilterActionsValue;
  onChange: (actionKey: ActionableSummaryFilterKey, nextValue: unknown) => void;
}

export const FilterActionsContext =
  React.createContext<FilterActionsContextValue>({
    filterActions: null,
    value: DEFAULT_FILTER_ACTIONS_VALUE,
    onChange: noop,
  });

export function useFilterActions() {
  return useContext(FilterActionsContext);
}
