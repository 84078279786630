import noop from 'lodash/noop';
import { useContext } from 'react';
import React from 'react';

import { AnalyticQueryFilter } from '@/api/query.ts';
import type { AnalyticTableFilter } from '@/api/useAnalyticsFiltersApi.ts';
import type { DatasetField } from '@/api/useFields.ts';
export type FilterValue = Record<DatasetField['field'], AnalyticQueryFilter>;

interface FilterContextValue {
  tableFilters: readonly AnalyticTableFilter[];
  value: FilterValue;
  onChange: (
    field: AnalyticQueryFilter['field'],
    queryFilter: AnalyticQueryFilter,
  ) => void;
  onClear: (field: AnalyticQueryFilter['field']) => void;
}

export const FilterContext = React.createContext<FilterContextValue>({
  tableFilters: [],
  value: {},
  onChange: noop,
  onClear: noop,
});

export function useFilters() {
  return useContext(FilterContext);
}
