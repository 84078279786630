import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useResolveStoryUrl } from '@/api/useReportFoldersApi.ts';
import { Tag } from '@/components/TagSelect.tsx';

import createApi from './apiFactory';
import {
  useFavouritesInvalidateQueries,
  useIsFavourite,
} from './useFavouritesApi';
import {
  ReportBlockEditor,
  ReportBlockPayloadAPI,
  cleanBlock,
  useInvalidateReportBlocks,
  useUpdateReportBlocksCache,
} from './useReportsBlocksApi';
import {
  ReportCollaborator,
  ReportCollaboratorRole,
} from './useReportsCollaboratorsApi';
import type { ChartTheme } from './useThemesApi';
import type { User } from './useUsersApi';
import { dialog } from '../components/AlertDialog';
import { useApi } from '../components/ApiContext';
import { ConflictError } from '../components/ApiProvider';
import type { OldFiltersValueThatNeedsMigration } from '../components/FilterV2/getFilterValues';
import { clearReportEditCache } from '../pages/stories/components/useStoryFormCache';
import useCurrentOrganizationSlug from '../utils/useCurrentOrganizationSlug';
import useMutationTrigger from '../utils/useMutationTrigger';

const REPORTS_API_ENDPOINT = 'reports';
const ORGANIZATION_SLUG_POSITION = 'url';
const DEFAULT_PAGE_SIZE = 12;
const WHITELIST_KEYS = [
  'filters',
  'filters',
  'data',
  'color_mapping',
  'colorMapping',
  'column_sizing',
  'columnSizing',
];

export type ColorMapping = Record<string, number | string>;

export type Report = {
  id: string;
  title: string;
  description?: string;
  filters: OldFiltersValueThatNeedsMigration;
  collaborators: ReportCollaborator[];
  viewerCollaboratorRole: ReportCollaboratorRole;
  viewerLastViewedAt: string | null;
  updatedAt: string;
  views: number;
  tags?: string[];
  createdBy: User;
  chartTheme?: ChartTheme;
  status: 'archived' | 'active';
  folders?: { id: string; name: string; icon?: string; color?: string }[];
  color?: string | null;
  icon?: string | null;
  /**
   * Color mapping represents the color or index of the chart for each series.
   */
  colorMapping: ColorMapping;
};

interface ReportView extends Pick<User, 'id' | 'name' | 'email' | 'avatarUrl'> {
  updatedAt: string;
  views: number;
}

type ReportUpdateInput = Partial<Omit<Report, 'id' | 'updatedAt'>> & {
  id: string;
  updatedAt: string;
  // blocks will be cleaned before sending to the API
  blocks?: ReportBlockEditor[];
};

type ReportUpdateResult = Omit<ReportUpdateInput, 'blocks'> & {
  blocks?: ReportBlockPayloadAPI[];
};

const {
  useGet,
  useInvalidate,
  useGetPath,
  useExtraQueryParams,
  useGetBaseQueryKey,
  usePaginatedList,
  useDelete,
} = createApi<Report>({
  basePath: REPORTS_API_ENDPOINT,
  defaultPageSize: DEFAULT_PAGE_SIZE,
  organisationSlugPosition: ORGANIZATION_SLUG_POSITION,
  whitelistKeys: WHITELIST_KEYS,
  paginationType: 'page',
});

export {
  useGet as useReport,
  usePaginatedList as useReports,
  useInvalidate as useReportsInvalidate,
};

export function useReportTags(reportId?: string) {
  const api = useApi();
  const getBasePath = useGetPath();
  const getQueryKey = useGetBaseQueryKey('tags');

  return useQuery({
    queryKey: getQueryKey(),
    queryFn: () => api.get<Tag[]>(`${getBasePath()}tags/`, { reportId }),
  });
}

export function useReportViews(reportId: string) {
  const api = useApi();
  const getBasePath = useGetPath();
  const getQueryKey = useGetBaseQueryKey(`${reportId}/views`);

  return useQuery({
    queryKey: getQueryKey(),
    queryFn: () => api.get<ReportView[]>(`${getBasePath(reportId)}views/`),
  });
}

export function useCreateEmptyReport(folderId?: string | null | undefined) {
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const getBasePath = useGetPath();
  const extraQueryParams = useExtraQueryParams();
  const invalidate = useInvalidate();
  const organizationSlug = useCurrentOrganizationSlug();
  const retrieveQueryKey = useGetBaseQueryKey('get');
  const updateReportBlocksCache = useUpdateReportBlocksCache();
  const queryClient = useQueryClient();

  return useMutationTrigger(
    () =>
      api.post<Report>(getBasePath(), { title: 'Untitled' }, extraQueryParams),
    {
      onSuccess: async (report) => {
        if (!report) {
          return;
        }

        await invalidate();

        // Seed the cache, so we don't have to wait for the report to be retrieved
        // note that we NEED to have this seeded so as not to re-request the report
        // which triggers a view increment and by extension updates the updatedAt
        queryClient.setQueryData(retrieveQueryKey(report.id), report);
        updateReportBlocksCache(report.id, []);

        if (!folderId) {
          folderId = report.folders?.[0]?.id;
        }

        const path = folderId
          ? `/${organizationSlug}/projects/${folderId}/stories/${report.id}/edit`
          : `/${organizationSlug}/stories/${report.id}/edit`;

        return navigate({
          pathname: path,
          search: location.search,
        });
      },
    },
  );
}

function useReportUnsynced() {
  const api = useApi();
  const navigate = useNavigate();
  const getBasePath = useGetPath();
  const extraQueryParams = useExtraQueryParams();
  const invalidate = useInvalidate();
  const invalidateReportBlocks = useInvalidateReportBlocks();
  const resolveStoryUrl = useResolveStoryUrl();

  return useMutation({
    mutationFn: async (report: ReportUpdateResult) => {
      try {
        await dialog({
          title: 'Story has been updated by another user',
          confirmation:
            'Do you want to create a new story with your changes or erase your changes and refresh?',
          options: {
            confirmLabel: 'Create new story',
            cancelLabel: 'Erase changes',
          },
        });
        const result = await api.post<Report>(
          getBasePath(),
          {
            ...report,
            id: undefined,
            title: `${report.title} (copy)`,
            blocks: report.blocks?.map((b) => ({ ...b, id: undefined })),
          },
          extraQueryParams,
          WHITELIST_KEYS,
        );
        await invalidate();
        toast.success('Story has been saved as a new story');
        return navigate(resolveStoryUrl(result!.id));
      } catch (e) {
        await invalidateReportBlocks(report.id);
        await invalidate();
      } finally {
        clearReportEditCache(report.id);
      }
    },
    mutationKey: ['story', 'save'],
  });
}

/**
 * This hook is used to save a report.
 * We can't use the default `useSave` hook from `createApi` because we're doing
 * some additional cleanup and checks before saving the report.
 * Note we also update the report blocks cache if the blocks have been updated.
 */
export function useSaveReport() {
  const api = useApi();

  const getBasePath = useGetPath();
  const extraQueryParams = useExtraQueryParams();
  const isFavourite = useIsFavourite('report');
  const invalidateFavouritesRequest = useFavouritesInvalidateQueries();
  const invalidate = useInvalidate();
  const invalidateReportBlocks = useInvalidateReportBlocks();
  const onReportUnsynced = useReportUnsynced();

  const mutation = useMutation({
    mutationFn: async (report: ReportUpdateInput) => {
      const blocks = report.blocks?.map(cleanBlock)?.filter(Boolean);
      const value: ReportUpdateResult = { ...report, blocks };
      try {
        return await api.put<Report>(
          getBasePath(report.id),
          { ...report, blocks },
          extraQueryParams,
          WHITELIST_KEYS,
        );
      } catch (error) {
        if (error instanceof ConflictError) {
          await onReportUnsynced.mutateAsync(value);
        }
      }
    },
    mutationKey: ['story', 'save'],
    onSuccess: async (newReport) => {
      if (!newReport) {
        return;
      }

      await Promise.all([invalidateReportBlocks(newReport.id), invalidate()]);

      // If the report is a favourite, we need to invalidate the favourites query
      if (newReport && isFavourite(newReport.id)) {
        invalidateFavouritesRequest();
      }
    },
  });

  const runMutation = (reportId: string, report: ReportUpdateInput) =>
    mutation.mutateAsync({ ...report, id: reportId });

  return [runMutation, mutation] as const;
}

interface ReportCounts {
  active: number;
  archived: number;
  templates: number;
  explores: number;
}

export function useReportCounts() {
  const api = useApi();
  const getQueryKey = useGetBaseQueryKey('counts');
  const getPath = useGetPath();

  return useQuery({
    queryKey: getQueryKey(),
    queryFn: () => api.get<ReportCounts>(`${getPath()}counts/`),
  });
}

function useInvalidateReportsCount() {
  const getQueryKey = useGetBaseQueryKey('counts');
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: getQueryKey() });
}

export function useDeleteReport() {
  const isFavourite = useIsFavourite('report');
  const deleteReport = useDelete();
  const invalidateFavouritesRequest = useFavouritesInvalidateQueries();
  const invalidateCounts = useInvalidateReportsCount();
  const navigate = useNavigate();
  const resolveStoryUrl = useResolveStoryUrl();

  return async (id: string) => {
    const result = await deleteReport(id);
    if (!result) {
      return;
    }

    await navigate(resolveStoryUrl(), { replace: true });
    toast.success('Story deleted');
    if (isFavourite(id)) {
      invalidateFavouritesRequest();
    }
    invalidateCounts();
  };
}

export function useArchiveReport() {
  const api = useApi();
  const invalidate = useInvalidate();
  const getPath = useGetPath();
  const extraQueryParams = useExtraQueryParams();
  const invalidateCounts = useInvalidateReportsCount();

  return useMutationTrigger(
    (id: string) =>
      dialog({
        title: 'Archive story',
        confirmation: 'Are you sure you want to archive this story?',
        options: {
          confirmLabel: 'Archive',
          confirmColor: 'danger',
        },
      })
        .then(() => api.post(`${getPath(id)}archive/`, extraQueryParams))
        .catch(() => null),
    {
      onSuccess: async (result: any) => {
        if (!result) {
          return;
        }

        toast.success('Story archived');
        await invalidate();
        await invalidateCounts();
      },
    },
  );
}

export function useUnarchiveReport() {
  const api = useApi();
  const invalidate = useInvalidate();
  const getPath = useGetPath();
  const extraQueryParams = useExtraQueryParams();
  const navigate = useNavigate();
  const invalidateCounts = useInvalidateReportsCount();
  const resolveStoryUrl = useResolveStoryUrl();

  return useMutationTrigger(
    (id: string) => api.post(`${getPath(id)}unarchive/`, extraQueryParams),
    {
      onSuccess: async (result: any, queryClient) => {
        if (!result) {
          return;
        }

        toast.success('Story unarchived', {
          action: {
            label: 'View',
            onClick: () => navigate(resolveStoryUrl(result.id)),
          },
        });
        await queryClient.invalidateQueries({ queryKey: ['organization'] });
        await invalidate();
        invalidateCounts();
      },
    },
  );
}

export function useCloneReport() {
  const api = useApi();
  const { folderId } = useParams();
  const getPath = useGetPath();
  const extraQueryParams = useExtraQueryParams();
  const navigate = useNavigate();
  const resolveStoryUrl = useResolveStoryUrl();

  const handleEntityClone = (id: string) => {
    const path = `${getPath(id)}clone/`;
    return api.post(path, { folder: folderId }, extraQueryParams);
  };
  const invalidate = useInvalidate();

  const cloneMutation = useMutation({
    mutationFn: handleEntityClone,
    onSuccess: async (_result: any) => {
      toast.success(`Story duplicated as ${_result.title}`, {
        action: {
          label: 'View',
          onClick: () => navigate(resolveStoryUrl(_result.id)),
        },
      });
      await invalidate();
    },
  });

  return (id: string) => cloneMutation.mutateAsync(id);
}
