import {
  InfiniteQueryObserverResult,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import snakeCase from 'lodash/snakeCase';
import { toast } from 'sonner';

import { deleteConfirmationDialog } from '../components/AlertDialog';
import { useApi } from '../components/ApiContext';
import { ClientError } from '../components/ApiProvider';
import { PaginatedQuery } from '../utils/types';
import useCurrentOrganizationSlug from '../utils/useCurrentOrganizationSlug';
import useMutationTrigger, {
  UseMutationTriggerConfig,
} from '../utils/useMutationTrigger';

const ORGANIZATIONS_URL_PREFIX = 'orgs';

type SlugPosition = 'url' | 'query-param' | 'none';

export type Ordering = {
  field: string;
  order: 'asc' | 'desc';
};

type BaseListProps = {
  q?: string;
  pageSize?: number;
  filters?: Record<string, any>;
  sort?: Ordering;
};

type ListProps = BaseListProps & {
  page?: number;
  cursor?: string;
};

type InfinityListOptions<TResult> = Omit<
  UseInfiniteQueryOptions<PaginatedQuery<TResult> | null>,
  'queryKey' | 'getNextPageParam' | 'initialPageParam'
>;

type QueryOptions<TResult> = Omit<
  UseQueryOptions<TResult | null>,
  'queryKey' | 'queryFn'
>;

// XXX: useList flattens the paginated data,
// but React-query's types don't work well with that so, we override it here.
export type ListQueryObserver<TData, TError = unknown> = Omit<
  InfiniteQueryObserverResult<TData, TError>,
  'data'
> & {
  data: PaginatedQuery<TData> | undefined;
};

// `useInvalidate` needed both in `.useInvalidate` and `.useSave`
// so it was moved into separate function with `endpoint` as a param
export const useInvalidate = (endpoint: string) => {
  const organizationSlug = useCurrentOrganizationSlug();
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({ queryKey: [organizationSlug, endpoint] });
};

function useGetBaseQueryKey(endpoint: string, action: string) {
  const organizationSlug = useCurrentOrganizationSlug();
  return (id?: string): QueryKey => {
    const result = [organizationSlug, endpoint, action];
    if (id) {
      result.push(id);
    }
    return result;
  };
}

export const useGetPath = (
  organisationSlugPosition: SlugPosition,
  endpoint: string,
) => {
  const organizationSlug = useCurrentOrganizationSlug();
  return (id?: string) => {
    let path = `${endpoint}/`;
    if (id) {
      path += `${id}/`;
    }

    if (organisationSlugPosition === 'url') {
      // e.g. `orgs/some-demo-org/reports/qwe12qwe-rt3u-io4p-asd5-gh6kl78cv9nm/`
      return `${ORGANIZATIONS_URL_PREFIX}/${organizationSlug}/${path}`;
    }
    // For `query-param` and `none` no need to add Org Slug into url
    return path;
  };
};

export const useExtraQueryParams = (organisationSlugPosition: SlugPosition) => {
  const organizationSlug = useCurrentOrganizationSlug();
  if (organisationSlugPosition === 'query-param') {
    return { organizationSlug };
  }
  // For `url` and `none` no need to add Org Slug into query params
  return {};
};

interface FactoryApiProps {
  // Resource name (`reports`)
  // or
  // Resource Path (`reports/<uuid>/collaborators`) in case when API is collaborator-oriented.
  //  so `useGet(other-uuid)` will be request to `reports/<uuid>/collaborators/<other-uuid>/`
  basePath: string;
  // TODO: `null` support might need to be added here for `All At Once` cases (when supported in API)
  defaultPageSize?: number;
  // Whether to put organisation slug into URL, or as a query param. Default: `url`
  organisationSlugPosition?: SlugPosition;
  paginationType:
    | 'none'
    | 'page'
    // Use when you have "cursor" key in your response body.
    // Why some endpoints were implemented with cursors:
    // https://cra.mr/2011/03/08/building-cursors-for-the-disqus-api/
    | 'cursor';
  onDeleteSuccess?: (id: string) => Promise<void>;
  whitelistKeys?: string[];
}

export default function createApi<
  TResult,
  TSaveData extends Record<string, any> = Record<string, any>,
>({
  basePath,
  organisationSlugPosition = 'url',
  paginationType,
  defaultPageSize = 20,
  onDeleteSuccess,
  whitelistKeys,
}: FactoryApiProps) {
  const normalizeOrderingParam = (sort?: Ordering) => {
    // Adding `ordering=xxx_yyy` instead of `xxxYyy` manually,
    // since `api` helpers are converting field names only and not values
    return (
      sort && `${sort.order === 'desc' ? '-' : ''}${snakeCase(sort.field)}`
    );
  };

  return {
    defaultPageSize,
    useInvalidate: () => {
      return useInvalidate(basePath);
    },
    useGetPath: () => {
      return useGetPath(organisationSlugPosition, basePath);
    },
    useGetBaseQueryKey: (action: string) => {
      return useGetBaseQueryKey(basePath, action);
    },
    useExtraQueryParams: () => {
      return useExtraQueryParams(organisationSlugPosition);
    },
    /*
     * LIST endpoint methods (you may want to use only one for resource):
     *
     * usePaginatedList use for page pagination:
     *   `page` pagination type:
     *      Response like: {"data": [{}, {}], "page": 1, "total": 100}
     *   `cursor` pagination type:
     *      Response like: {"data": [{}, {}], "cursor": "..."}
     * useList use for resources without pagination:
     *   Response like: [{}, {}]
     *
     * // TODO: allow `none` pagination type as option for factory, so user should have only one useList() method
     */
    usePaginatedList: (
      {
        q,
        pageSize = defaultPageSize,
        page = 1,
        sort,
        filters,
      }: ListProps = {},
      options: InfinityListOptions<TResult> = {},
    ) => {
      const api = useApi();
      const getBaseQueryKey = useGetBaseQueryKey(basePath, 'page');
      const ordering = normalizeOrderingParam(sort);
      const baseQueryCacheKeys = [pageSize, q, ordering, filters];
      if (paginationType === 'page') {
        baseQueryCacheKeys.push(page);
      }
      const queryKey = [...getBaseQueryKey(), baseQueryCacheKeys];
      const getPath = useGetPath(organisationSlugPosition, basePath);
      const path = getPath();
      const extraQueryParams = useExtraQueryParams(organisationSlugPosition);
      return useInfiniteQuery<PaginatedQuery<TResult> | null>({
        ...options,
        queryKey,
        queryFn: ({ pageParam }) => {
          const queryParams: Record<string, any> = {
            pageSize,
            ordering,
            q,
            ...filters,
            ...extraQueryParams,
          };
          if (paginationType === 'page') {
            // pageParam is provided when called via `fetchNextPage` (see getNextPageParam)
            // Otherwise use provided page number from params
            queryParams.page = pageParam || page;
          }
          if (paginationType === 'cursor') {
            queryParams.cursor = pageParam;
          }

          return api.get<PaginatedQuery<TResult>>(
            path,
            queryParams,
            whitelistKeys,
          );
        },
        // @ts-expect-error No solution to this right now https://github.com/tannerlinsley/react-query/discussions/1410#discussioncomment-215143
        select: (data) => {
          // For `page` pagination there will always be only 1 page (`last` page) and it will have proper `count`
          // And for `cursor` pagination, `last` page will have proper `next` / `previous` values
          const { count, next, previous } =
            data.pages[data.pages.length - 1] || {};

          // Returning `PaginatedQuery`-like result to be consistent with regular `useQuery`
          return {
            count,
            next,
            previous,
            results: data.pages.flatMap(
              (resultPage) => resultPage?.results || [],
            ),
          };
        },
        getNextPageParam: (lastPage) => {
          if (!lastPage || !lastPage.next) {
            return null;
          }
          if (paginationType === 'cursor') {
            return lastPage.next;
          }
          // In case of `page` pagination `next` is a URL to the next page
          // Parsing the URL to get the `page=X` value
          const nextUrl = new URL(lastPage.next);
          return nextUrl.searchParams.get('page');
        },
      }) as unknown as ListQueryObserver<TResult>;
    },
    // Use for raw [{...}, {...}] response without any pagination
    useList: (listProps?: ListProps, options: QueryOptions<TResult[]> = {}) => {
      const { sort, filters } = listProps || {};
      const api = useApi();
      const getBaseQueryKey = useGetBaseQueryKey(basePath, 'list');
      const ordering = normalizeOrderingParam(sort);
      const queryKey = [...getBaseQueryKey(), [ordering, filters]] as QueryKey;
      const getPath = useGetPath(organisationSlugPosition, basePath);
      const path = getPath();
      const extraQueryParams = useExtraQueryParams(organisationSlugPosition);

      return useQuery({
        ...options,
        queryKey,
        queryFn: () =>
          api.get<TResult[]>(
            path,
            { ordering, ...filters, ...extraQueryParams },
            whitelistKeys,
          ),
      });
    },
    useGet: (id: string | undefined, options: QueryOptions<TResult> = {}) => {
      const api = useApi();
      const getBaseQueryKey = useGetBaseQueryKey(basePath, 'get');
      const queryKey = getBaseQueryKey(id);
      const getPath = useGetPath(organisationSlugPosition, basePath);
      const path = getPath(id);
      const extraQueryParams = useExtraQueryParams(organisationSlugPosition);
      const query = useQuery({
        ...options,
        queryKey,
        queryFn: () => api.get<TResult>(path, extraQueryParams, whitelistKeys),
        // Prevent running query when id is not provided
        enabled: !!id && options.enabled,
        staleTime: 100,
      });

      return { ...query, isLoading: !id ? false : query.isLoading };
    },
    useDelete: (withConfirmation = true) => {
      const api = useApi();
      const getPath = useGetPath(organisationSlugPosition, basePath);
      const extraQueryParams = useExtraQueryParams(organisationSlugPosition);
      const handleEntityDelete = (id: string) => {
        const path = getPath(id);
        return api.delete(path, undefined, extraQueryParams);
      };
      const invalidate = useInvalidate(basePath);

      const deleteMutation = useMutation({
        mutationFn: handleEntityDelete,
        onSuccess: async (_result: any, id: string) => {
          if (onDeleteSuccess) {
            await Promise.resolve(onDeleteSuccess(id));
          }
          await invalidate();
        },
        onError: (err) => {
          if (err instanceof ClientError) {
            toast.error(err.message);
          }
        },
      });

      if (withConfirmation) {
        return (id: string) => {
          return deleteConfirmationDialog()
            .then(() => deleteMutation.mutateAsync(id))
            .then(() => true)
            .catch(() => null); // we are catching to swallow the error if user cancels
        };
      }

      return (id: string) => deleteMutation.mutateAsync(id);
    },
    useSave<TData extends Record<string, unknown> = TSaveData>(
      config?: UseMutationTriggerConfig<TResult, TData> & { partial?: boolean },
    ) {
      const api = useApi();
      const getPath = useGetPath(organisationSlugPosition, basePath);
      const extraQueryParams = useExtraQueryParams(organisationSlugPosition);

      const handleEntitySave = ({ id, ...data }: TData & { id?: string }) => {
        const path = getPath(id);
        let method: 'put' | 'patch' | 'post';
        if (id) {
          method = config?.partial ? 'patch' : 'put';
        } else {
          method = 'post';
        }

        return api[method]<TResult, TData>(
          path,
          data as TData,
          extraQueryParams,
          whitelistKeys,
        );
      };
      const invalidate = useInvalidate(basePath);
      return useMutationTrigger(handleEntitySave, {
        ...config,
        onSuccess: async (result, queryClient, data) => {
          await invalidate();
          if (config?.onSuccess) {
            await config?.onSuccess(result, queryClient, data);
          }
        },
      });
    },
  };
}
