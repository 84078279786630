import { createContext } from '@eqtble/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FeatureFlag } from '../../utils/featureFlag';
import { initPosthog } from '../../utils/posthog';

interface FeatureFlagsContext {
  features: Set<FeatureFlag>;
}

const [FeatureFlagsContextProvider, useFeatureFlags] =
  createContext<FeatureFlagsContext>({
    name: 'FeatureFlagsContext',
  });

export function useFeatureFlag(feature: FeatureFlag): boolean {
  const { features } = useFeatureFlags();
  return features.has(feature);
}

interface Props {
  children: React.ReactNode;
}

function FeatureFlagsProvider({ children }: Props) {
  const [features, setFeatures] = useState<Set<FeatureFlag>>(new Set());
  const posthog = initPosthog();

  const getEnabledFlags = useCallback(
    (flags: FeatureFlag[]): void => {
      const enabledFlags = flags.filter((f) => {
        return posthog?.getFeatureFlag(f) === true;
      });
      setFeatures(new Set(enabledFlags));
    },
    [posthog, setFeatures],
  );

  useEffect(() => {
    if (posthog && !features) {
      const interval = setInterval(() => {
        posthog?.featureFlags.reloadFeatureFlags();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [posthog, features, getEnabledFlags]);

  useEffect(() => {
    posthog?.onFeatureFlags(getEnabledFlags as any);
  }, [posthog, getEnabledFlags]);

  const context = useMemo(() => ({ features }), [features]);
  return (
    <FeatureFlagsContextProvider value={context}>
      {children}
    </FeatureFlagsContextProvider>
  );
}

export default FeatureFlagsProvider;
