/* eslint-disable @typescript-eslint/naming-convention */
import { current, isDraft, setAutoFreeze } from 'immer';
import getDeep from 'lodash/get';
import setDeep from 'lodash/set';
import { v4 as uuidv4 } from 'uuid';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type {
  AiData,
  BlockType,
  MediaData,
  QuartoData,
  Query,
  ReportBlockEditor,
  TextData,
} from '@/api/useReportsBlocksApi.ts';
import { useConstant } from '@/utils/useConstant.ts';

import { FilterValue } from '../FilterV2/FilterContext';
import { FilterActionsValue } from '../FilterV2/FilterProvider/FilterActionsContext';

// Auto-freeze is disabled because React-Grid-Layout mutates the layout
setAutoFreeze(false);

export interface Layout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minH?: number;
}

const DEFAULT_HEIGHT: Record<BlockType, number> = {
  query: 500,
  media: 350,
  quarto: 350,
  embed: 350,
  text: 120,
  divider: 40,
  ai: 300,
};

// Currently only used in v2. Note that v2 calculates height by the entire block, so include padding and header
export const MIN_HEIGHT: Record<BlockType, number> = {
  query: 350,
  media: 100,
  quarto: 100,
  embed: 100,
  text: 40,
  divider: 40,
  ai: 300,
};

const COLUMN_COUNT = 4;
const DEFAULT_WIDTH: Record<BlockType, number> = {
  query: 2,
  media: 2,
  ai: 4,
  quarto: 4,
  embed: 4,
  text: COLUMN_COUNT,
  divider: COLUMN_COUNT,
};

function getDefaultBlock(): Record<BlockId, ReportBlockEditor> {
  // Regenerate the default block id each time to avoid conflicts
  const defaultBlockId = uuidv4();

  return {
    [defaultBlockId]: {
      id: defaultBlockId,
      type: 'text',
      data: null as any,
      layout: { h: DEFAULT_HEIGHT.text, i: defaultBlockId, w: 4, x: 0, y: 0 },
    },
  };
}

function isEmptyData(data: TextData | null): boolean {
  if (!data) {
    return true;
  }

  if (data.type === 'doc') {
    return !data.content?.some((d) => !isEmptyData(d as any as TextData));
  }

  if (data.type === 'paragraph' || data.type === 'heading') {
    return !data.content?.some((d) => {
      return (d.type === 'text' && !!d.text) || d.type === 'kpi';
    });
  }

  return false;
}

function isEmptyBlocks(blocks: ReportBlockEditor[] | null | undefined) {
  if (!blocks || !blocks.length) {
    return true;
  }

  // check if only one text block and it's empty
  return (
    blocks.length === 1 &&
    blocks[0].type === 'text' &&
    isEmptyData(blocks[0].data as TextData)
  );
}

function createDataChanges(
  state: ReportBlockStoreValue,
  block: ReportBlockEditor,
  data: any,
): ReportBlockChange[] {
  // if the block is new with no current data, then we merge the previous 'add' change

  if (block.__isNew && !block.data && state.changes.length > 0) {
    const previousChange = state.changes.pop()!;
    return previousChange.map((change) => {
      if (change.block.id !== block.id) {
        return change;
      }

      change.block.data = data;
      return change;
    });
  }

  return [
    {
      op: 'replace',
      block,
      path: ['data'],
      value: data,
    },
  ];
}

type BlockId = string;
type ReportBlockChange =
  | { op: 'add'; block: ReportBlockEditor }
  | { op: 'replace'; block: ReportBlockEditor; path: string[]; value: any }
  | { op: 'remove'; block: ReportBlockEditor };

interface ReportBlockStoreActions {
  onTextHeightChange: (id: BlockId, height: number) => void;
  onDataChange: (
    id: BlockId,
    data: TextData | Partial<Query> | MediaData | QuartoData,
    trackChange?: boolean,
  ) => void;
  onLayoutChange: (layouts: Layout[]) => void;
  onAddBlock: (
    type: BlockType,
    data?: TextData | Partial<Query> | MediaData,
  ) => void;
  onAddBlockToRow: (
    type: BlockType,
    row: number,
    initialData?: TextData | Partial<Query> | MediaData,
  ) => void;
  onCloneBlock: (id: BlockId) => void;
  onDeleteBlock: (id: BlockId) => void;
  onAiDataChange: (id: BlockId, data: AiData) => void;
  onAiBlockSave: (id: BlockId) => void;
  onSetKpiOpen: (kpiId: string | null | undefined) => void;
  onSetBlockSelectingInsight: (id: BlockId, isOpen: boolean) => void;
  onSetIsisBubbleMenuOpen: (isOpen: boolean) => void;
  onChartSettingsChange: (id: BlockId, key: string, value: any) => void;
  onFiltersChange: (id: BlockId, filtersOverride: FilterValue | null) => void;
  onGranularityFiltersChange: (
    id: BlockId,
    granularityFilters: FilterActionsValue | null,
  ) => void;

  setBlocks: (blocks: ReportBlockEditor[]) => void;

  undo(): void;
  redo(): void;
}

interface ReportBlockStoreValue {
  blocksById: Record<BlockId, ReportBlockEditor>;
  blockSelectingInsight: BlockId | null | undefined;
  isBubbleMenuOpen: boolean;
  kpiOpen: string | null | undefined;
  actions: ReportBlockStoreActions;
  changes: ReportBlockChange[][];
  redoChanges: ReportBlockChange[][];
  onChange: (blocks: ReportBlockEditor[]) => void;
}

function unpack<T>(item: T): T {
  return isDraft(item) ? current(item) : item;
}

function getBlocks(state: ReportBlockStoreValue) {
  return Object.values(unpack(state.blocksById));
}

function findSpaceInRow({
  row,
  width,
  startX = 0,
}: {
  row: ReportBlockEditor[];
  width: number;
  startX?: number;
}) {
  const sortedRow = row.sort((a, b) => a.layout.x - b.layout.x);
  const totalWidth = sortedRow.reduce((acc, block) => acc + block.layout.w, 0);
  if (totalWidth + width > COLUMN_COUNT) {
    return;
  }

  // Iterate over the row and for each block, calculate the start and end of the block to check if there is space
  const positions: Record<number, boolean> = {};
  sortedRow.forEach((block) => {
    positions[block.layout.x] = true;
    for (let i = 0; i < block.layout.w; i++) {
      positions[block.layout.x + i] = true;
    }
  });

  let emptySpace = 0;
  for (let i = startX; i < COLUMN_COUNT; i++) {
    if (positions[i]) {
      emptySpace = 0;
    } else {
      emptySpace++;
    }
    if (emptySpace === width) {
      return i - width + 1;
    }
  }
}

function getNewBlock(
  type: BlockType,
  x = 0,
  y = 0,
  width = 0,
): ReportBlockEditor {
  const height = DEFAULT_HEIGHT[type];

  const id = uuidv4();

  return {
    id,
    type,
    data: null as any,
    layout: {
      x,
      y,
      w: width || DEFAULT_WIDTH[type],
      h: height,
      i: id,
    },
    __isNew: true,
  };
}

function calculateBlockLocation({
  blocks,
  width,
  startY = 0,
  startX = 0,
  onlyCheckLast = false,
}: {
  blocks: ReportBlockEditor[];
  width: number;
  startY?: number;
  startX?: number;
  onlyCheckLast?: boolean;
}): { x: number; y: number } | undefined {
  const blocksByRow: Record<number, ReportBlockEditor[]> = {};
  blocks.forEach((block) => {
    const { y, h } = block.layout;
    blocksByRow[y + h] = [...(blocksByRow[y + h] ?? []), block];
  }, {});

  // Calculate the range of rows that we need to check
  const rows = Object.keys(blocksByRow)
    .map((r) => Number.parseInt(r, 10))
    .sort((a, b) => b - a);
  const rowsToCheck = onlyCheckLast
    ? [rows[0]]
    : rows.filter((r) => r >= startY);

  // Check each row for a space
  for (let i = rowsToCheck.length - 1; i >= 0; i--) {
    const row = rowsToCheck[i];
    const blocksInRow = blocksByRow[row] ?? [];
    const x = findSpaceInRow({ row: blocksInRow, width, startX });
    if (x !== undefined) {
      return { x, y: row };
    }
  }
}

function getSelectedBlockFromUrl(blocks: Record<BlockId, ReportBlockEditor>) {
  const searchParams = new URLSearchParams(window.location.search);
  const selectedBlockQueryParam = searchParams.get('selectedBlock');

  if (!selectedBlockQueryParam || !blocks[selectedBlockQueryParam]) {
    return null;
  }

  return blocks[selectedBlockQueryParam];
}

export function setSelectedBlockInUrl(blockId: BlockId | null) {
  const searchParams = new URLSearchParams(window.location.search);
  if (blockId) {
    searchParams.set('selectedBlock', blockId);
  } else {
    searchParams.delete('selectedBlock');
  }
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${searchParams.toString()}`,
  );
}

function createActions(
  set: (fn: (state: ReportBlockStoreValue) => void) => void,
): ReportBlockStoreActions {
  return {
    setBlocks: (blocks) => {
      set((state) => {
        let blocksById: Record<BlockId, ReportBlockEditor> = {};
        const isEmptyBlock = isEmptyBlocks(blocks);
        if (blocks && blocks.length > 0 && !isEmptyBlock) {
          blocks.forEach((block) => {
            blocksById[block.id] = block;
          });
        } else {
          blocksById = getDefaultBlock();
        }

        state.blocksById = blocksById;
      });
    },
    onTextHeightChange: (id, height) =>
      set((state) => {
        // this is for editor v2. v1 doesn't have minH
        state.blocksById[id].layout.minH = height;
      }),
    onDataChange: (id, data, trackChange = true) =>
      set((state) => {
        const block = unpack(state.blocksById[id]);
        if (trackChange) {
          state.changes.push(createDataChanges(state, block, data));
          state.redoChanges = [];
        }
        state.blocksById[id].data = data;
        state.onChange(getBlocks(state));
      }),
    onLayoutChange: (layouts) =>
      set((state) => {
        const changes: ReportBlockChange[] = [];
        layouts.forEach((layout) => {
          const blockId = layout.i;
          const currentLayout = state.blocksById[blockId].layout;
          const currentMinHeight = currentLayout.minH;

          if (
            currentLayout.x !== layout.x ||
            currentLayout.y !== layout.y ||
            currentLayout.w !== layout.w ||
            currentLayout.h !== layout.h
          ) {
            changes.push({
              block: unpack(state.blocksById[blockId]),
              op: 'replace',
              path: ['layout'],
              value: layout,
            });
          }

          state.blocksById[blockId].layout = layout;
          if (currentMinHeight && !layout.minH) {
            state.blocksById[blockId].layout.minH = currentMinHeight;
          }
        });

        if (changes.length > 0) {
          state.changes.push(changes);
          state.redoChanges = [];
          state.onChange(getBlocks(state));
        }
      }),
    onAddBlock: (type, data) =>
      set((state) => {
        const width = DEFAULT_WIDTH[type];
        const height = DEFAULT_HEIGHT[type];

        const blocks = Object.values(state.blocksById);
        // Attempt to pack the new block into the last row
        const space = calculateBlockLocation({
          blocks,
          width,
          onlyCheckLast: true,
        });

        let x: number;
        let y: number;
        if (space) {
          x = space.x;
          y = space.y;
        } else {
          x = 0;
          const maxY = Math.max(...blocks.map((b) => b.layout.y));
          y = maxY + height;
        }

        const newBlock = getNewBlock(type, x, y);

        if (!data && type === 'query') {
          state.blockSelectingInsight = newBlock.id;
          setSelectedBlockInUrl(newBlock.id);
        }

        if (data) {
          newBlock.data = data;
        }

        // If there's only the default block, then just remove it
        if (isEmptyBlocks(blocks)) {
          state.blocksById = { [newBlock.id]: newBlock };
        } else {
          state.blocksById[newBlock.id] = newBlock;
        }

        if (type === 'divider' || !!data) {
          state.onChange(getBlocks(state));
        }
        state.changes.push([{ op: 'add', block: newBlock }]);
        state.redoChanges = [];
      }),
    onAddBlockToRow: (type, row, initialData) => {
      // Attempts to pack the block into the row by resizing the row's blocks to fit
      // NOTE this is explicitly built for V2 story editor
      set((state) => {
        const allBlocks = Object.values(state.blocksById);

        const changes: ReportBlockChange[] = [];

        // ROW_COL_SPAN is from eqtble/ui's GridLayout
        const ROW_COL_SPAN = 12;

        let newBlock: ReportBlockEditor;

        // If there's only the default block, then just remove it and add the new block
        if (isEmptyBlocks(allBlocks)) {
          newBlock = getNewBlock(type, 0, row, ROW_COL_SPAN);
          state.blocksById = {};
        } else {
          const blocks = allBlocks
            .filter((block) => block.layout.y === row)
            .sort((a, b) => a.layout.x - b.layout.x);

          const colSpan = ROW_COL_SPAN / (blocks.length + 1);
          newBlock = getNewBlock(type, blocks.length, row, colSpan);

          blocks.forEach((block) => {
            changes.push({
              block: unpack(block),
              op: 'replace',
              path: ['layout'],
              value: colSpan,
            });
            block.layout.w = colSpan;
          });
        }

        if (type === 'query' && !initialData) {
          state.blockSelectingInsight = newBlock.id;
          setSelectedBlockInUrl(newBlock.id);
        }

        if (initialData) {
          newBlock.data = initialData;
        }

        state.blocksById[newBlock.id] = newBlock;

        if (type === 'divider' || !!initialData) {
          state.onChange(getBlocks(state));
        }

        changes.push({ op: 'add', block: newBlock });
        state.changes.push(changes);
        state.redoChanges = [];
      });
    },
    onCloneBlock: (id) =>
      set((state) => {
        const block = state.blocksById[id];
        const currentBlock = unpack(block)!;
        // Check to see if there's space to the immediate right of the block.
        // If not, let the editor handle the layout change
        const space = calculateBlockLocation({
          blocks: Object.values(state.blocksById),
          width: block.layout.w,
          startY: block.layout.y + block.layout.h,
          startX: block.layout.x,
        });

        const newBlock = {
          ...currentBlock,
          id: uuidv4(),
          layout: {
            ...currentBlock.layout,
            ...(space ?? {}),
          },
          __isNew: true,
        };

        state.blocksById[newBlock.id] = newBlock;
        state.changes.push([
          {
            op: 'add',
            block: newBlock,
          },
        ]);
        state.redoChanges = [];
        state.onChange(getBlocks(state));
      }),
    onDeleteBlock: (id) => {
      set((state) => {
        const blocks = Object.values(state.blocksById);
        if (isEmptyBlocks(blocks)) {
          return;
        }
        const block = state.blocksById[id];
        delete state.blocksById[id];
        if (Object.keys(state.blocksById).length === 0) {
          state.blocksById = getDefaultBlock();
        }

        state.changes.push([{ op: 'remove', block: unpack(block)! }]);
        state.redoChanges = [];
        state.onChange(getBlocks(state));
      });
    },
    onAiDataChange: (id, data) =>
      set((state) => {
        const block = state.blocksById[id];
        if (!block) {
          return;
        }
        state.changes.push(createDataChanges(state, block, data));
        state.redoChanges = [];

        block.data = data;
        // Once a chart is displayed, we can set the height to the default
        block.layout.h = DEFAULT_HEIGHT.query;
        state.onChange(getBlocks(state));
      }),
    onAiBlockSave: (id) =>
      set((state) => {
        const block = state.blocksById[id];
        if (!block) {
          return;
        }

        const query = (block.data as AiData)?.result;
        if (!query || !query.data || !query.data.fields?.length) {
          return;
        }

        const newBlock = {
          ...block,
          type: 'query',
          data: query,
        } satisfies ReportBlockEditor;
        state.blocksById[id] = newBlock;
        state.changes.push([
          {
            block: unpack(block)!,
            op: 'replace',
            path: [],
            value: newBlock,
          },
        ]);
        state.redoChanges = [];
      }),
    onSetBlockSelectingInsight: (id, isOpen) =>
      set((state) => {
        state.isBubbleMenuOpen = false;
        state.blockSelectingInsight = isOpen ? id : null;

        setSelectedBlockInUrl(state.blockSelectingInsight);

        // if we're closing a query block, and it's net new, just delete it
        const block = state.blocksById[id];
        if (
          !isOpen &&
          block &&
          block.type === 'query' &&
          block.__isNew &&
          !(block.data as Partial<Query>)?.data?.fields?.length
        ) {
          delete state.blocksById[id];
        }
      }),
    onSetIsisBubbleMenuOpen: (isOpen) =>
      set((state) => {
        state.isBubbleMenuOpen = isOpen;
      }),
    onSetKpiOpen: (kpiId) =>
      set((state) => {
        state.kpiOpen = kpiId;
        state.isBubbleMenuOpen = false;
      }),
    onChartSettingsChange: (id, key, value) =>
      set((state) => {
        const block = state.blocksById[id];
        if (!block) {
          return;
        }

        state.changes.push([
          {
            block: unpack(block)!,
            op: 'replace',
            path: ['data', 'chartSettings', key],
            value,
          },
        ]);
        state.redoChanges = [];
        setDeep(block, ['data', 'chartSettings', key], value);
        state.onChange(getBlocks(state));
      }),
    onFiltersChange: (id, filtersOverride) =>
      set((state) => {
        const block = state.blocksById[id];
        if (!block) {
          return;
        }
        state.changes.push([
          {
            block: unpack(block)!,
            op: 'replace',
            path: ['data', 'filtersOverride'],
            value: filtersOverride,
          },
        ]);
        state.redoChanges = [];
        setDeep(block, ['data', 'filtersOverride'], filtersOverride);
        state.onChange(getBlocks(state));
      }),
    onGranularityFiltersChange: (id, granularityFilters) =>
      set((state) => {
        const block = state.blocksById[id];
        if (!block) {
          return;
        }
        state.changes.push([
          {
            block: unpack(block),
            op: 'replace',
            path: ['data', 'granularityFilters'],
            value: granularityFilters,
          },
        ]);
        state.redoChanges = [];
        setDeep(block, ['data', 'granularityFilters'], granularityFilters);
        state.onChange(getBlocks(state));
      }),

    undo: () => {
      set((state) => {
        if (state.changes.length === 0) {
          return;
        }
        const changes: ReportBlockChange[] = state.changes.pop()!;
        const redoChanges: ReportBlockChange[] = [];
        changes.forEach((change) => {
          if (change.op === 'add') {
            delete state.blocksById[change.block.id];
          } else if (change.op === 'remove') {
            state.blocksById[change.block.id] = change.block;
          } else if (change.op === 'replace') {
            const currentBlock = state.blocksById[change.block.id];
            if (!currentBlock) {
              return;
            }
            const prevValue = unpack(getDeep(change.block, change.path));
            setDeep(currentBlock, change.path, prevValue);
          }
          redoChanges.push(change);
        });
        state.redoChanges.push(redoChanges);
        state.onChange(getBlocks(state));
      });
    },

    redo: () => {
      set((state) => {
        if (state.redoChanges.length === 0) {
          return;
        }
        const redoChanges = state.redoChanges.pop()!;
        const changes: ReportBlockChange[] = [];
        redoChanges.forEach((change) => {
          if (change.op === 'add') {
            state.blocksById[change.block.id] = change.block;
          } else if (change.op === 'remove') {
            delete state.blocksById[change.block.id];
          } else if (change.op === 'replace') {
            const block = state.blocksById[change.block.id];
            if (!block) {
              return;
            }
            setDeep(block, change.path, change.value);
          }
          changes.push(change);
        });
        state.changes.push(changes);
        state.onChange(getBlocks(state));
      });
    },
  };
}

function createChangeHandler(onChange: (blocks: ReportBlockEditor[]) => void) {
  return (blocks: ReportBlockEditor[]) => {
    const cleanedBlocks = blocks.filter((block) => {
      // filter out invalid blocks
      if (block.type === 'text') {
        return !isEmptyData(block.data as TextData);
      }
      if (block.type === 'query') {
        return !!(block.data as Query)?.data?.fields?.length;
      }
      if (block.type === 'media') {
        return (
          !!(block.data as MediaData)?.image?.id ||
          !!(block.data as MediaData)?.video
        );
      }
      return true;
    });

    onChange(cleanedBlocks);
  };
}

const createReportBlocksStore = ({
  isReadOnly,
  initialBlocks,
  onChange,
}: {
  isReadOnly: boolean;
  initialBlocks: ReportBlockEditor[] | null | undefined;
  onChange: (blocks: ReportBlockEditor[]) => void;
}) => {
  const store = createStore<ReportBlockStoreValue>()(
    devtools(
      immer((set) => ({
        blocksById: {},
        changes: [],
        redoChanges: [],
        blockSelectingInsight: null,
        isBubbleMenuOpen: false,
        kpiOpen: null,
        lastUpdatedAt: new Date(),
        onChange: createChangeHandler(onChange),
        actions: createActions(set),
      })),
    ),
  );

  const currentBlocks = getBlocks(store.getState());
  if (isReadOnly || isEmptyBlocks(currentBlocks)) {
    // if the report is read-only or the current blocks are empty, we need update the store to the initial blocks
    store.getState().actions.setBlocks(initialBlocks || []);
  } else {
    // We're in edit mode and storage has some unsaved changes.
    // Call onChange to make sure the current blocks from storage are set in the parent component
    store.getState().onChange(currentBlocks);
    // also call setBlocks to initialize the store with the current blocks from storage
  }

  // check the query param "selectedBlock" to see if we should select a block by default
  const selectedBlock = getSelectedBlockFromUrl(store.getState().blocksById);
  if (selectedBlock) {
    store.getState().actions.onSetBlockSelectingInsight(selectedBlock.id, true);
  }

  return store;
};

export function useReportBlocksStore({
  isReadOnly,
  initialBlocks,
  onChange,
}: {
  isReadOnly: boolean;
  initialBlocks: ReportBlockEditor[] | null | undefined;
  onChange: (blocks: ReportBlockEditor[]) => void;
}) {
  return useConstant(() =>
    createReportBlocksStore({
      isReadOnly,
      initialBlocks,
      onChange,
    }),
  );
}
