/* eslint-disable @typescript-eslint/naming-convention */
import { randomColorFromString } from '@eqtble/utils';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { type VariantProps, cva } from 'class-variance-authority';
import cx from 'clsx';
import React from 'react';

import { lightenDarkenColor } from '@/components/Charts/colorUtils.ts';
import { useTheme } from '@/components/ThemeProvider.tsx';

function initials(name: string) {
  const [firstName, lastName] = name.split(' ');
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : firstName.charAt(0);
}

const DEFAULT_ICON = (
  <svg fill="currentColor" viewBox="0 0 24 24">
    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
);

const avatarVariants = cva(
  [
    // Basic layout
    'inline-grid align-middle shrink-0 *:col-start-1 *:row-start-1',
    'outline outline-1 -outline-offset-1 outline-foreground/10',
  ],
  {
    variants: {
      size: {
        '2xs': 'size-4 text-[0.4rem] leading-4',
        xs: 'size-5 text-[0.5rem] leading-5',
        sm: 'size-6 text-[0.6rem] leading-6',
        md: 'size-8 text-[0.8rem] leading-8',
        lg: 'size-12 text-[1.2rem] leading-[3rem]',
      },
      rounded: {
        full: 'rounded-full *:rounded-full',
        square: 'rounded-[20%] *:rounded-[20%]',
      },
    },
    defaultVariants: {
      size: 'md',
      rounded: 'full',
    },
  },
);

export type AvatarSize = VariantProps<typeof avatarVariants>['size'];

export interface AvatarProps
  extends Omit<React.ComponentProps<typeof AvatarPrimitive.Root>, 'size'>,
    VariantProps<typeof avatarVariants> {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  src?: string;
  srcSet?: string;
  icon?: React.ReactNode;
  backgroundColor?: string;
}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(
  (
    {
      className,
      name,
      backgroundColor: backgroundColorProp,
      src,
      srcSet,
      size,
      rounded,
      icon = DEFAULT_ICON,
      ...props
    },
    ref,
  ) => {
    const { theme } = useTheme();
    const backgroundColor = React.useMemo(() => {
      if (backgroundColorProp) {
        return backgroundColorProp;
      }

      if (name) {
        return randomColorFromString(name);
      }
    }, [name, backgroundColorProp]);

    return (
      <AvatarPrimitive.Root
        ref={ref}
        className={avatarVariants({ size, rounded, className })}
        {...props}
        data-slot="avatar"
      >
        {src || srcSet ? (
          <AvatarPrimitive.Image
            alt={name}
            src={src}
            srcSet={srcSet}
            className="aspect-square"
          />
        ) : null}
        <AvatarPrimitive.Fallback
          delayMs={150}
          className="flex items-center justify-center overflow-hidden"
          style={{ backgroundColor }}
        >
          {name ? (
            <svg
              className={cx(
                'select-none text-[42px] font-medium uppercase',
                !backgroundColor && 'fill-foreground',
              )}
              viewBox="0 0 100 100"
              style={
                backgroundColor
                  ? {
                      fill: lightenDarkenColor(
                        backgroundColor,
                        theme === 'dark' ? -60 : 60,
                      ),
                    }
                  : undefined
              }
            >
              <title>{name}</title>
              <text
                x="50%"
                y="50%"
                alignmentBaseline="middle"
                dominantBaseline="middle"
                textAnchor="middle"
                dy=".125em"
              >
                {initials(name)}
              </text>
            </svg>
          ) : (
            icon
          )}
        </AvatarPrimitive.Fallback>
      </AvatarPrimitive.Root>
    );
  },
);

Avatar.displayName = 'Avatar';

export interface AvatarGroupProps extends VariantProps<typeof avatarVariants> {
  children?: React.ReactNode;
  className?: string;
  /**
   * The maximum number of visible avatars
   */
  max?: number;
}

const avatarGroupVariants = cva(
  'flex items-center justify-center overflow-hidden',
  {
    variants: {
      size: {
        '2xs': '-space-x-1.5',
        xs: '-space-x-2',
        sm: '-space-x-2',
        md: '-space-x-3',
        lg: '-space-x-5',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

const avatarGroupAvatarVariants = cva('ring-2 ring-white dark:ring-gray-900', {
  variants: {
    size: {
      '2xs': 'size-4',
      xs: 'size-5',
      sm: 'size-6',
      md: 'size-8',
      lg: 'size-12',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(
  ({ children, className, max = 3, size = 'md', rounded, ...props }, ref) => {
    const childrenArray = React.Children.toArray(children).filter(
      React.isValidElement,
    );

    // get the avatars within the max
    const childrenWithinMax = max ? childrenArray.slice(0, max) : childrenArray;
    // get the overflow count
    const excess = max != null && childrenArray.length - max;

    // Reversing the children is a great way to avoid using zIndex to overlap the avatars
    const reversedChildren = childrenWithinMax.reverse();

    const clones = reversedChildren.map((child) => {
      const childProps = {
        size,
        rounded,
        className: avatarGroupAvatarVariants({ size }),
      };

      return React.cloneElement(child, childProps);
    });

    return (
      <div
        {...props}
        ref={ref}
        className={avatarGroupVariants({ size, className })}
      >
        {clones}
        {excess !== false && excess > 0 && (
          <span
            className={avatarVariants({
              size,
              rounded,
              className:
                'flex items-center justify-center bg-background font-medium',
            })}
          >{`+${excess}`}</span>
        )}
      </div>
    );
  },
);

AvatarGroup.displayName = 'AvatarGroup';

export { Avatar, AvatarGroup };
