import { FormErrorMessage } from '@eqtble/ui';
import { ErrorMessage } from '@hookform/error-message';
import type { FieldValuesFromFieldErrors } from '@hookform/error-message/dist/types';
import { useCallback } from 'react';
import React from 'react';
import type { FieldErrors, FieldName } from 'react-hook-form';
import { useIntl } from 'react-intl';
interface FormMessageProps<TFieldErrors extends FieldErrors> {
  name: FieldName<FieldValuesFromFieldErrors<TFieldErrors>>;
  className?: string;
  errors?: TFieldErrors;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-testid'?: string;
}

/**
 * Displays a react-formal error message matching the provided `for` path.
 */
function FieldError<TFieldErrors extends FieldErrors>({
  className,
  name,
  'data-testid': dataCy,
  errors,
}: FormMessageProps<TFieldErrors>) {
  const { formatMessage } = useIntl();

  const getMessage = useCallback(
    (error: any) => {
      if (!error) {
        return;
      }

      const msg = error.message || error;
      if (typeof msg === 'string' || React.isValidElement(msg)) {
        return msg;
      }

      if (!msg.id) {
        return null;
      }

      return formatMessage(msg, msg.values || error?.values);
    },
    [formatMessage],
  );

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => (
        <FormErrorMessage id={name} className={className} data-testid={dataCy}>
          {getMessage(message)}
        </FormErrorMessage>
      )}
    />
  );
}

export default FieldError;
