import { defaultStackParser, makeFetchTransport } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

if (process.env.EQT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    dsn: process.env.EQT_PUBLIC_SENTRY_DSN,
    tunnel: `${process.env.EQT_PUBLIC_API_BASE}/api/v1/sentry/`,
    environment: process.env.EQT_ENV,
    tracesSampleRate: 0.3,
    tracePropagationTargets: [new URL(process.env.EQT_PUBLIC_API_BASE!).host],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  });
}

if (process.env.EQT_PUBLIC_PYLON_APP_ID) {
  // Set your APP_ID
  const APP_ID = process.env.EQT_PUBLIC_PYLON_APP_ID;
  /* eslint-disable */
  (function () {
    var e = window;
    var t = document;
    var n = function () {
      // @ts-ignore
      n.e(arguments);
    };
    // @ts-ignore
    n.q = [];
    // @ts-ignore
    n.e = function (e) {
      // @ts-ignore
      n.q.push(e);
    };
    // @ts-ignore
    e.Pylon = n;
    var r = function () {
      var e = t.createElement('script');
      e.setAttribute('type', 'text/javascript');
      e.setAttribute('async', 'true');
      e.setAttribute('src', `https://widget.usepylon.com/widget/${APP_ID}`);
      var n = t.getElementsByTagName('script')[0];
      n.parentNode?.insertBefore(e, n);
    };
    if (t.readyState === 'complete') {
      r();
    } else if (e.addEventListener) {
      e.addEventListener('load', r, false);
    }
  })();
  /* eslint-enable */
}
