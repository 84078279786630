import { BarChartIcon } from 'lucide-react';

import { Report } from '@/api/useReportsApi.ts';
import { IconAvatar } from '@/components/IconPicker/IconAvatar.tsx';
import { AvatarProps } from '@/components/ui/avatar';

export interface StoryIconProps {
  story: Pick<Report, 'icon' | 'color'> | null | undefined;
  size?: AvatarProps['size'];
  className?: string;
}

export function StoryIcon({ story, size, className }: StoryIconProps) {
  return (
    <IconAvatar
      icon={story?.icon}
      color={story?.color}
      size={size}
      className={className}
      defaultIcon={<BarChartIcon className="size-[1.5em]" />}
    />
  );
}
