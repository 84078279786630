import {
  Button,
  ButtonColor,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogTitle,
} from '@eqtble/ui';
import {
  ConfirmDialogProps,
  confirmable,
  createConfirmationCreater,
  createMountPoint,
  createReactTreeMounter,
} from 'react-confirm';

type ConfirmationModalProps = {
  show: boolean;
  handleClose: VoidFunction;
  title?: React.ReactNode;
  confirmation: React.ReactNode;
  handleConfirm: VoidFunction;
  confirmColor?: ButtonColor;
  confirmLabel?: React.ReactNode;
  cancelLabel?: React.ReactNode;
};

/**
 * Used as a part of AlertDialog.
 * Can be used separately for cases, when confirmation modal needs to be rendered inside of other modal, etc.
 */
function ConfirmationModal({
  show,
  handleClose,
  title,
  confirmation,
  handleConfirm,
  confirmColor = 'primary',
  confirmLabel = 'Ok',
  cancelLabel = 'Cancel',
}: ConfirmationModalProps) {
  return (
    <Dialog isOpen={show} data-testid="confirmation-modal">
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogBody>{confirmation}</DialogBody>
      <DialogFooter>
        <Button color="secondary" onClick={handleClose}>
          {cancelLabel}
        </Button>
        <Button onClick={handleConfirm} color={confirmColor}>
          {confirmLabel}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

interface CreateConfirmationProps {
  title?: React.ReactNode;
  confirmation?: React.ReactNode;
  options?: {
    confirmLabel?: string;
    confirmColor?: ButtonColor;
    cancelLabel?: string;
  };
}

interface Props extends ConfirmDialogProps<any, any>, CreateConfirmationProps {}

function AlertDialog({
  show,
  proceed,
  cancel,
  title,
  confirmation = 'Are you sure you want to delete this item? You cannot undo this action.',
  options: { confirmLabel, confirmColor, cancelLabel } = {},
}: Props) {
  const handleConfirm = () => proceed(confirmLabel);
  const handleClose = () => cancel();

  return (
    <ConfirmationModal
      show={show}
      handleClose={handleClose}
      title={title}
      confirmation={confirmation}
      handleConfirm={handleConfirm}
      confirmColor={confirmColor}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
    />
  );
}

const mounter = createReactTreeMounter();
const createConfirmation = createConfirmationCreater(mounter);
export const ConfirmationContainer = createMountPoint(mounter);

export const dialog = (props: CreateConfirmationProps) =>
  createConfirmation(confirmable(AlertDialog))(props);

export const deleteConfirmationDialog = (title = 'Delete') =>
  dialog({
    title,
    confirmation:
      'Are you sure you want to delete this item? You cannot undo this action.',
    options: {
      confirmLabel: 'Delete',
      confirmColor: 'danger',
    },
  });
