import { defineMessages } from 'react-intl';

export const pages = defineMessages({
  organizationSettings: {
    id: 'pages.organizationSettings',
    defaultMessage: 'Organization settings',
  },
  myProfile: {
    id: 'pages.myProfile',
    defaultMessage: 'My profile',
  },
  allStories: {
    id: 'pages.allStories',
    defaultMessage: 'All Stories',
  },
  projects: {
    id: 'pages.projects',
    defaultMessage: 'Projects',
  },
  stories: {
    id: 'pages.stories',
    defaultMessage: 'Stories',
  },
  explore: {
    id: 'pages.explore',
    defaultMessage: 'Explore',
  },
  savedCharts: {
    id: 'pages.savedCharts',
    defaultMessage: 'Saved Charts',
  },
  mySavedCharts: {
    id: 'pages.mySavedCharts',
    defaultMessage: 'My Saved Charts',
  },
  bookshelf: {
    id: 'pages.bookshelf',
    defaultMessage: 'Bookshelf',
  },
  alerts: {
    id: 'pages.alerts',
    defaultMessage: 'Alerts',
  },
  employees: {
    id: 'pages.employees',
    defaultMessage: 'Employees',
  },
  archivedStories: {
    id: 'pages.archivedStories',
    defaultMessage: 'Archived Stories',
  },
  favorites: {
    id: 'pages.favorites',
    defaultMessage: 'Favorites',
  },
  dataLibrary: {
    id: 'pages.dataLibrary',
    defaultMessage: 'Data Library',
  },
  metrics: {
    id: 'pages.metrics',
    defaultMessage: 'Metrics',
  },
  storyBlocks: {
    id: 'pages.storyBlocks',
    defaultMessage: 'Story Blocks',
  },
  fields: {
    id: 'pages.fields',
    defaultMessage: 'Fields',
  },
  dashboards: {
    id: 'pages.dashboards',
    defaultMessage: 'Dashboards',
  },
  notificationTemplates: {
    id: 'pages.notificationTemplates',
    defaultMessage: 'Notification Templates',
  },
  dataPermissions: {
    id: 'pages.dataPermissions',
    defaultMessage: 'Data Permissions',
  },
  eqtbleAi: {
    id: 'pages.eqtbleAi',
    defaultMessage: 'eqtble AI',
  },
  integrations: {
    id: 'pages.integrations',
    defaultMessage: 'Integrations',
  },
  users: {
    id: 'pages.users',
    defaultMessage: 'Users',
  },
  teams: {
    id: 'pages.teams',
    defaultMessage: 'Teams',
  },
  chartThemes: {
    id: 'pages.chartThemes',
    defaultMessage: 'Chart Themes',
  },
  segments: {
    id: 'pages.segments',
    defaultMessage: 'Segments',
  },
  calculatedFields: {
    id: 'pages.calculatedFields',
    defaultMessage: 'Calculated Fields',
  },
});
