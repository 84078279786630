import isPlainObject from 'lodash/isPlainObject';

import { parseDate } from '../../utils/dates';

export type Preset = string;
export type Interval =
  | 'days'
  | 'weeks'
  | 'months'
  | 'quarters'
  | 'years'
  | 'fiscal_months'
  | 'fiscal_quarters'
  | 'fiscal_years';

export const FISCAL_INTERVALS: Interval[] = [
  'fiscal_months',
  'fiscal_quarters',
  'fiscal_years',
];

export type FixedCalenderRangeValue = [startDate: string, endDate: string];
export type SinceRangeValue = [sinceDate: string] | string;
export type LastRangeValue = {
  value: number;
  interval: Interval;
  completedOnly?: boolean | undefined | null;
};
export type DateRangePickerValue =
  | FixedCalenderRangeValue
  | SinceRangeValue
  | LastRangeValue
  | Preset
  | null
  | undefined;

export function isPreset(
  value:
    | DateRangePickerValue
    | string
    | string[]
    | Record<string, unknown>
    | any,
): value is Preset {
  return value != null && typeof value === 'string';
}

export function isLastRangeValue(
  value: DateRangePickerValue | string | string[] | Record<string, unknown>,
): value is LastRangeValue {
  return value != null && isPlainObject(value);
}

export function isSinceRangeValue(
  value: DateRangePickerValue | string | string[] | Record<string, unknown>,
): value is SinceRangeValue {
  return (
    value != null &&
    ((Array.isArray(value) && value.length === 1) || parseDate(value) != null)
  );
}
export function isFixedRangeValue(
  value: DateRangePickerValue | string | string[] | Record<string, unknown>,
): value is FixedCalenderRangeValue {
  return value != null && Array.isArray(value) && value.length === 2;
}
