import posthog from 'posthog-js';

import { FeatureFlag } from './featureFlag';

/**
 * PostHog is a library for tracking events/recordings/actions/feature flags.
 *
 * @see https://posthog.com/docs/
 */
export function initPosthog() {
  if (!process.env.EQT_PUBLIC_POSTHOG_TOKEN) {
    return null;
  }

  posthog.init(process.env.EQT_PUBLIC_POSTHOG_TOKEN, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    api_host: process.env.EQT_PUBLIC_POSTHOG_URL,
  });

  return posthog;
}

const identify: typeof posthog.identify = (...props) => {
  const initialisedPosthog = initPosthog();
  if (initialisedPosthog) {
    initialisedPosthog.identify(...props);
  }
};

const capture: typeof posthog.capture = (...props) => {
  // We don't need to track events/recordings/actions for local setup.
  // The only way to use posthog for local setup is feature flag info.
  // For feature flags you have to pass `force=true` argument.
  if (process.env.EQT_ENV === 'local') {
    return;
  }

  const initialisedPosthog = initPosthog();
  if (initialisedPosthog) {
    initialisedPosthog.capture(...props);
  }
};

const group: typeof posthog.group = (...props) => {
  const initialisedPosthog = initPosthog();
  if (initialisedPosthog) {
    initialisedPosthog.group(...props);
  }
};

const onFeatureFlags = (callback: (featureFlags: FeatureFlag[]) => void) => {
  const initialisedPosthog = initPosthog();
  if (initialisedPosthog) {
    initialisedPosthog.onFeatureFlags(
      callback as (featureFlags: string[]) => void,
    );
  }
};

export default {
  identify,
  capture,
  group,
  onFeatureFlags,
};
