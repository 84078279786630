import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useCallback, useMemo } from 'react';

import type { Method, RequestBody } from './ApiContext';
import { ApiContext } from './ApiContext';
import getPayloadData, { deepMapToSnakeCase } from '../utils/getPayloadData';
import getResponseData from '../utils/getResponseData';
import useLogout from '../utils/useLogout';

const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred.';

class ApiError extends Error {
  constructor(
    message: string,
    public errors?: any,
  ) {
    super(message);
  }
}

export class ClientError extends ApiError {}

class UpstreamError extends ApiError {}

class NotFoundError extends ClientError {}

export class ConflictError extends ClientError {}

async function resolveError(response: Response) {
  let body;
  let errors;

  try {
    body = await response.text();
    errors = JSON.parse(body).errors || [];
  } catch (e) {
    errors = [];
  }

  const { status } = response;

  if (status >= 500) {
    return new UpstreamError(DEFAULT_ERROR_MESSAGE);
  }

  if (!Array.isArray(errors)) {
    return new ClientError(DEFAULT_ERROR_MESSAGE);
  }

  const firstMessage = errors?.[0]?.detail || DEFAULT_ERROR_MESSAGE;

  if (status === 404) {
    return new NotFoundError(firstMessage);
  }

  if (status === 409) {
    return new ConflictError(firstMessage);
  }

  return new ClientError(firstMessage, errors);
}

function getApiUrl(path: string) {
  return `${process.env.EQT_PUBLIC_API_BASE}/api/v1/${path}`;
}

function getQueryParams(queryParams: Record<string, unknown>) {
  const params: Record<string, unknown> = {};

  // filter our null/undefined values
  Object.keys(queryParams).forEach((param) => {
    if (queryParams[param] != null) {
      params[param] = queryParams[param];
    }
  });

  return new URLSearchParams(deepMapToSnakeCase(params));
}

interface Props {
  children: React.ReactNode;
}

export function ApiProvider({ children }: Props) {
  const auth0 = useAuth0();
  const logout = useLogout();

  const request = useCallback(
    async <TResult, TBody = RequestBody>(
      method: Method,
      path: string,
      data?: TBody | FormData,
      whitelistKeys?: false | string[],
      signal?: AbortSignal,
    ): Promise<TResult | null> => {
      const url = getApiUrl(path);

      const init: RequestInit = {
        method,
        credentials: 'same-origin',
        signal,
      };

      try {
        const token = await auth0.getAccessTokenSilently();
        if (token) {
          init.headers = { Authorization: `Bearer ${token}` };
        }
      } catch (error) {
        Sentry.captureException(error);
        logout();
        return null;
      }

      if (data) {
        if (data instanceof FormData) {
          init.body = data;
        } else {
          init.headers = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
            ...init.headers,
          };
          init.body =
            whitelistKeys !== false
              ? getPayloadData(data, whitelistKeys)
              : JSON.stringify(data);
        }
      }

      const response = await fetch(url, init);

      // FIXME: this is a really ugly hack to get around unauthenticated requests
      if (response.status === 401) {
        window.location.pathname = '/auth/logout';
        return null;
      }

      if (
        response.status === 204 ||
        (response.status === 404 && method === 'GET')
      ) {
        return null;
      }

      if (!response.ok) {
        throw await resolveError(response);
      }

      // Check the headers, if the content type is not JSON, return text
      const contentType = response.headers.get('content-type');
      if (contentType && !contentType.includes('application/json')) {
        return (await response.text()) as unknown as TResult;
      }

      const body = await response.json();
      return (
        whitelistKeys !== false ? getResponseData(body, whitelistKeys) : body
      ) as TResult;
    },
    [auth0, logout],
  );

  const get = useCallback(
    <TResult,>(
      path: string,
      queryParams: Record<string, unknown> = {},
      whitelistKeys: false | string[] = [],
      signal: AbortSignal | undefined = undefined,
    ) => {
      const params = getQueryParams(queryParams);
      return request<TResult>(
        'GET',
        `${path}?${params}`,
        undefined,
        whitelistKeys,
        signal,
      );
    },
    [request],
  );

  const post = useCallback(
    <TResult, TBody = RequestBody>(
      path: string,
      data?: TBody | FormData,
      queryParams: Record<string, unknown> = {},
      whitelistKeys: false | string[] = [],
      signal: AbortSignal | undefined = undefined,
    ) => {
      const params = getQueryParams(queryParams);
      return request<TResult, TBody>(
        'POST',
        `${path}?${params}`,
        data,
        whitelistKeys,
        signal,
      );
    },
    [request],
  );

  const put = useCallback(
    <TResult, TBody = RequestBody>(
      path: string,
      data?: TBody | FormData,
      queryParams: Record<string, unknown> = {},
      whitelistKeys: false | string[] = [],
    ) => {
      const params = getQueryParams(queryParams);
      return request<TResult, TBody>(
        'PUT',
        `${path}?${params}`,
        data,
        whitelistKeys,
      );
    },
    [request],
  );

  const patch = useCallback(
    <TResult, TBody = RequestBody>(
      path: string,
      data?: TBody | FormData,
      queryParams: Record<string, unknown> = {},
      whitelistKeys: false | string[] = [],
    ) => {
      const params = getQueryParams(queryParams);
      return request<TResult, TBody>(
        'PATCH',
        `${path}?${params}`,
        data,
        whitelistKeys,
      );
    },
    [request],
  );

  const del = useCallback(
    <TResult, TBody = RequestBody>(
      path: string,
      data?: TBody,
      queryParams: Record<string, unknown> = {},
    ) => {
      const params = getQueryParams(queryParams);
      return request<TResult, TBody>('DELETE', `${path}?${params}`, data);
    },
    [request],
  );

  const api = useMemo(
    () => ({
      get,
      post,
      put,
      patch,
      delete: del,
      request,
    }),
    [get, post, put, patch, del, request],
  );

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}
