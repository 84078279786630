import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import React, { useCallback, useContext, useMemo } from 'react';

import { useColorMappingSelector } from './ChartThemeProvider';
import { ChartTheme } from '../../api/useThemesApi';
import { useTheme } from '../ThemeProvider';

export const DEFAULT_LIGHT_SERIES_COLORS = [
  '#35919F',
  '#FF7557',
  '#80E1D9',
  '#F8BC3B',
  '#B2596E',
  '#72BEF4',
  '#FFB27A',
  '#0D7EA0',
  '#3BA974',
  '#FEBBB2',
  '#CA80DC',
  '#5BB7AF',
];

const DEFAULT_DARK_SERIES_COLORS = [
  '#429eac',
  '#ff8264',
  '#8deee6',
  '#ffc948',
  '#bf667b',
  '#7fcbff',
  '#ffbf87',
  '#1a8bad',
  '#48b681',
  '#ffc8bf',
  '#d78de9',
  '#68c4bc',
];

interface ChartColorsProviderValue {
  colors: string[];
  getColor: (indexOrColor: number | string) => string;
  getColorByKey: (...key: string[]) => string;
}

const ChartColorsContext = React.createContext<ChartColorsProviderValue>({
  colors: DEFAULT_LIGHT_SERIES_COLORS,
  getColor: () => DEFAULT_LIGHT_SERIES_COLORS[0],
  getColorByKey: () => DEFAULT_LIGHT_SERIES_COLORS[0],
});

export function useChartColors() {
  return useContext(ChartColorsContext);
}

interface ChartColorsProviderProps {
  children: React.ReactNode;
  colors?: ChartTheme['colors'] | null | undefined;
}

export function ChartColorsProvider({
  children,
  colors: themeColors,
}: ChartColorsProviderProps) {
  const appTheme = useTheme();

  // ChartColors must be used inside a ChartColorsProvider
  const colorMapping = useColorMappingSelector((state) => state.colorMapping);
  const { registerColor } = useColorMappingSelector((state) => state.actions);

  const colors = useMemo(() => {
    if (themeColors) {
      return themeColors;
    }

    return appTheme.theme === 'dark'
      ? DEFAULT_DARK_SERIES_COLORS
      : DEFAULT_LIGHT_SERIES_COLORS;
  }, [appTheme, themeColors]);

  /**
   * Returns the color at the given index or the color itself if it's a string.
   */
  const getColor = useCallback(
    (indexOrColor: number | string) => {
      if (typeof indexOrColor === 'number') {
        return colors[indexOrColor % colors.length];
      }

      // If the color is a string, we assume it's a hex color
      return indexOrColor;
    },
    [colors],
  );

  const getColorByKey = useCallback(
    (...keys: string[]) => {
      // XXX: explicitly snake casing the key to avoid our clumsy api normalization
      const snakeCased = keys.map((key) => snakeCase(key));
      const color = getColor(get(colorMapping, snakeCased));

      if (window?.IS_EXPORTING) {
        // Ignore registering while in exporting mode
        return color;
      }

      // We need to register the color in the store to keep the color mapping in sync with the chart theme.
      // We use queueMicrotask to make sure the registration happens after the current task.
      queueMicrotask(() => {
        registerColor(snakeCased);
      });

      return color;
    },
    [colorMapping, getColor, registerColor],
  );

  const value = useMemo(
    () => ({ colors, getColor, getColorByKey }),
    [colors, getColor, getColorByKey],
  );

  return (
    <ChartColorsContext.Provider value={value}>
      {children}
    </ChartColorsContext.Provider>
  );
}
