import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { reset } from './analytics';

export enum ReasonCode {
  LOGOUT = 'logout',
  IDLE = 'idle',
}

export default function useLogout(
  reason: ReasonCode = ReasonCode.LOGOUT,
  connection: string | null | undefined = undefined,
) {
  const auth = useAuth0();

  return useCallback(() => {
    const p = new URLSearchParams([
      ['reason', reason],
      ['prevPath', window.location.pathname],
    ]);
    if (connection) {
      p.append('connection', connection);
    }

    reset();
    return auth.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/auth/logout?${p.toString()}`,
      },
    });
  }, [auth, reason, connection]);
}
