/* eslint-disable @typescript-eslint/naming-convention */
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  invalidDateError: {
    id: 'components.DateRangePicker.invalidDateError',
    defaultMessage: 'Invalid date',
    description: 'Error message for invalid date',
  },
  lastLabel: {
    id: 'components.DateRangePicker.lastLabel',
    defaultMessage: 'Last {value} {interval}',
    description: 'Label for preset range',
  },
  lastCompletedLabel: {
    id: 'components.DateRangePicker.lastCompletedLabel',
    defaultMessage: 'Last completed {value} {interval}',
    description: 'Label for preset range with completed periods only',
  },
  sinceLabel: {
    id: 'components.DateRangePicker.sinceLabel',
    defaultMessage: 'Since {value}',
    description: 'Label for since range',
  },
  beforeLabel: {
    id: 'components.DateRangePicker.beforeLabel',
    defaultMessage: 'Before {value}',
    description: 'Label for before range',
  },
  onLabel: {
    id: 'components.DateRangePicker.onLabel',
    defaultMessage: 'On {value}',
    description: 'Label for on range',
  },
  fixedLabel: {
    id: 'components.DateRangePicker.fixedLabel',
    defaultMessage: '{startDate} to {endDate}',
    description: 'Label for fixed range',
  },
  thisIntervalLabel: {
    id: 'components.DateRangePicker.thisIntervalLabel',
    defaultMessage: 'This {interval}',
    description: 'Label for this interval range',
  },
  selectADate: {
    id: 'components.DateRangePicker.selectADate',
    defaultMessage: 'Select a date',
  },
  startDate: {
    id: 'components.DateRangePicker.startDate',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'components.DateRangePicker.endDate',
    defaultMessage: 'End date',
  },
  inputPlaceholder: {
    id: 'components.DateRangePicker.inputPlaceholder',
    defaultMessage: 'Try "last 2 weeks"',
  },
  custom: {
    id: 'components.DateRangePicker.customDateRange',
    defaultMessage: 'Custom',
  },
  fixed: {
    id: 'components.DateRangePicker.fixedDateRange',
    defaultMessage: 'Fixed',
  },
  since: {
    id: 'components.DateRangePicker.sinceDateRange',
    defaultMessage: 'Since',
  },
  last: {
    id: 'components.DateRangePicker.lastDateRange',
    defaultMessage: 'Last',
  },
  today: {
    id: 'components.DateRangePicker.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'components.DateRangePicker.yesterday',
    defaultMessage: 'Yesterday',
  },
  last7Days: {
    id: 'components.DateRangePicker.last7Days',
    defaultMessage: 'Last 7 days',
  },
  last30Days: {
    id: 'components.DateRangePicker.last30Days',
    defaultMessage: 'Last 30 days',
  },
  last3Months: {
    id: 'components.DateRangePicker.last3Months',
    defaultMessage: 'Last 3 months',
  },
  last6Months: {
    id: 'components.DateRangePicker.last6Months',
    defaultMessage: 'Last 6 months',
  },
  last12Months: {
    id: 'components.DateRangePicker.last12Months',
    defaultMessage: 'Last 12 months',
  },
  day: {
    id: 'components.DateRangePicker.day',
    defaultMessage: 'Day',
  },
  days: {
    id: 'components.DateRangePicker.days',
    defaultMessage: 'days',
  },
  week: {
    id: 'components.DateRangePicker.week',
    defaultMessage: 'Week',
  },
  weeks: {
    id: 'components.DateRangePicker.weeks',
    defaultMessage: 'weeks',
  },
  month: {
    id: 'components.DateRangePicker.month',
    defaultMessage: 'Month',
  },
  months: {
    id: 'components.DateRangePicker.months',
    defaultMessage: 'months',
  },
  quarter: {
    id: 'components.DateRangePicker.quarter',
    defaultMessage: 'Quarter',
  },
  quarters: {
    id: 'components.DateRangePicker.quarters',
    defaultMessage: 'quarters',
  },
  year: {
    id: 'components.DateRangePicker.year',
    defaultMessage: 'Year',
  },
  years: {
    id: 'components.DateRangePicker.years',
    defaultMessage: 'years',
  },
  fiscalMonth: {
    id: 'components.DateRangePicker.fiscalMonth',
    defaultMessage: 'Fiscal month',
  },
  fiscal_months: {
    id: 'components.DateRangePicker.fiscalMonths',
    defaultMessage: 'fiscal months',
  },
  fiscalQuarter: {
    id: 'components.DateRangePicker.fiscalQuarter',
    defaultMessage: 'Fiscal quarter',
  },
  fiscal_quarters: {
    id: 'components.DateRangePicker.fiscalQuarters',
    defaultMessage: 'fiscal quarters',
  },
  fiscalYear: {
    id: 'components.DateRangePicker.fiscalYear',
    defaultMessage: 'Fiscal year',
  },
  fiscal_years: {
    id: 'components.DateRangePicker.fiscalYears',
    defaultMessage: 'fiscal years',
  },
  showOnlyCompletedPeriods: {
    id: 'components.DateRangePicker.showOnlyCompleted',
    defaultMessage: 'Show only completed periods',
  },
  enterValue: {
    id: 'components.DateRangePicker.enterValue',
    defaultMessage: 'Enter a value',
  },
});
