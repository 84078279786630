import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import createApi, {
  useExtraQueryParams,
  useGetPath,
  useInvalidate,
} from './apiFactory';
import { Member } from './useTeamsApi';
import { useApi } from '../components/ApiContext';

const API_ENDPOINT = 'users';
const ORGANIZATION_SLUG_POSITION = 'url';
const DEFAULT_PAGE_SIZE = 20;

type UserRoles = 'admin' | 'member';

export const ROLES_DATA: { value: UserRoles; label: string }[] = [
  { value: 'member', label: 'Member' },
  { value: 'admin', label: 'Admin' },
];

type TeamInUser = {
  id: string;
  name: string;
  membersCount: number;
};

export type User = {
  id: string;
  name: string;
  email: string;
  teamsCount: number;
  avatarUrl: string;
  role: 'admin' | 'member';
  teams: TeamInUser[];
};

export const usersApi = {
  ...createApi<User>({
    basePath: API_ENDPOINT,
    organisationSlugPosition: ORGANIZATION_SLUG_POSITION,
    paginationType: 'page',
    defaultPageSize: DEFAULT_PAGE_SIZE,
  }),
  useReassignAndDelete: () => {
    const api = useApi();
    const getBasePath = useGetPath(ORGANIZATION_SLUG_POSITION, API_ENDPOINT);
    const extraQueryParams = useExtraQueryParams(ORGANIZATION_SLUG_POSITION);
    const invalidate = useInvalidate(API_ENDPOINT);

    const handleReassignAndDelete = ({
      memberId,
      reassignToMemberId,
    }: {
      memberId: string;
      reassignToMemberId: string;
    }) => {
      const path = `${getBasePath(memberId)}delete-and-transfer-ownership/`;
      return api.post<Member>(
        path,
        { to: reassignToMemberId },
        extraQueryParams,
      );
    };

    const reassignAndDeleteMutation = useMutation({
      mutationFn: handleReassignAndDelete,
      onSuccess: invalidate,
    });
    return ({
      memberId,
      reassignToMemberId,
    }: {
      memberId: string;
      reassignToMemberId: string;
    }) =>
      reassignAndDeleteMutation.mutateAsync({ memberId, reassignToMemberId });
  },
  useBulkAdd: () => {
    const api = useApi();
    const getBasePath = useGetPath(ORGANIZATION_SLUG_POSITION, 'add-members');
    const path = getBasePath();
    const extraQueryParams = useExtraQueryParams(ORGANIZATION_SLUG_POSITION);
    const invalidate = useInvalidate(API_ENDPOINT);

    const handleBulkAdd = (payload: {
      emails: string[];
      role: string;
      teams?: string[];
    }) => {
      return api.post<Member>(path, payload, extraQueryParams);
    };

    const bulkAddMutation = useMutation({
      mutationFn: handleBulkAdd,
      onSuccess: invalidate,
    });
    return (payload: { emails: string[]; role: string; teams?: string[] }) =>
      bulkAddMutation.mutateAsync(payload);
  },
  useSetDefaultReport: () => {
    const api = useApi();
    const getBasePath = useGetPath(ORGANIZATION_SLUG_POSITION, API_ENDPOINT);
    const extraQueryParams = useExtraQueryParams(ORGANIZATION_SLUG_POSITION);
    const queryClient = useQueryClient();

    const handleSetDefaultReport = ({
      memberId,
      reportId,
    }: {
      memberId: string;
      reportId: string | null;
    }) => {
      const path = `${getBasePath(memberId)}default-report/`;
      return api.post(path, { reportId }, extraQueryParams);
    };

    const mutation = useMutation({
      mutationFn: handleSetDefaultReport,
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['organization'] });
        toast.success('Homepage updated');
      },
    });
    return mutation.mutateAsync;
  },
  useImpersonate: () => {
    const api = useApi();
    const getBasePath = useGetPath(ORGANIZATION_SLUG_POSITION, API_ENDPOINT);
    const extraQueryParams = useExtraQueryParams(ORGANIZATION_SLUG_POSITION);

    const handleImpersonate = (memberId: string) => {
      const path = `${getBasePath(memberId)}impersonate/`;
      return api.post<User>(path, {}, extraQueryParams);
    };

    const mutation = useMutation({
      mutationFn: handleImpersonate,
      onSuccess: (result) => {
        if (!result) {
          return;
        }

        // Hard refresh to clear the cache
        window.location.href = '/';
      },
    });
    return mutation.mutateAsync;
  },
  useStopImpersonating: () => {
    const api = useApi();
    const getBasePath = useGetPath(ORGANIZATION_SLUG_POSITION, API_ENDPOINT);
    const extraQueryParams = useExtraQueryParams(ORGANIZATION_SLUG_POSITION);

    const handleStopImpersonating = () => {
      const path = `${getBasePath()}stop-impersonating/`;
      return api.post<User>(path, {}, extraQueryParams);
    };

    const mutation = useMutation({
      mutationFn: handleStopImpersonating,
      onSuccess: () => {
        // Hard refresh to clear the cache
        window.location.href = '/';
      },
    });
    return mutation.mutateAsync;
  },
};
