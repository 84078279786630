import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Organization } from './useOrganizationApi';
import { useApi } from '../components/ApiContext';
import { FeatureFlag } from '../utils/featureFlag';
import { QueryOptions } from '../utils/types';
import useMutationTrigger from '../utils/useMutationTrigger';

export const API_ENDPOINT = 'me';

export interface UserInfo {
  id: string;
  email: string;
  organizations: Pick<Organization, 'id' | 'name' | 'slug' | 'avatar'>[];
  name: string;
  avatarUrl: string;
  isStaff: boolean;
  isOnboarded: boolean;
  featureFlags: Record<FeatureFlag, boolean | string>;
  pylonHash: string;
  onboardedAt: string;
  isImpersonated?: boolean;
  impersonatedBy?: {
    id: string;
    name: string;
    email: string;
  };
}

export default function useMeApi(options: QueryOptions<UserInfo | null> = {}) {
  const api = useApi();
  return useQuery<UserInfo | null>({
    ...options,
    queryKey: [API_ENDPOINT],
    queryFn: () => api.get<UserInfo>(`${API_ENDPOINT}/`),
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    placeholderData: (prev) => prev,
  });
}

export function useCompleteOnboarding({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutationTrigger(
    (values: {
      companyRole?: string | null | undefined;
      mainProblem?: string | null | undefined;
      organization: string;
    }) => api.post('me/onboarding-complete/', values),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [API_ENDPOINT] });
        onSuccess?.();
      },
    },
  );
}
