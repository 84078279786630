import { VizType } from '@/components/Query/VizType.ts';

import createApi from './apiFactory';

const API_ENDPOINT = 'search';
const ORGANIZATION_SLUG_POSITION = 'url';

type SearchResultTeamMetadata = {
  id: string;
  name: string;
};

type SearchResultUserMetadata = {
  id: string;
  // `name` is a string, but can be an empty string
  name: string;
  email: string;
  avatarUrl: string;
};

type SearchResultReportMetadata = {
  id: string;
  title: string;
  description?: string;
  icon?: string;
  color?: string;
};

type SearchResultFolderMetadata = {
  id: string;
  title: string;
  description?: string;
  icon?: string;
  color?: string;
};

type SearchResultReportBlockMetadata = {
  id: string;
  reportId: string;
  title: string;
  description?: string;
  vizType: VizType;
};

type SearchResultExploreMetadata = {
  id: string;
  title: string;
  description?: string;
};

export type SearchResultUser = {
  id: string;
  type: 'user';
  metadata: SearchResultUserMetadata;
};

export type SearchResultTeam = {
  id: string;
  type: 'team';
  metadata: SearchResultTeamMetadata;
};

type SearchResultReport = {
  id: string;
  type: 'report';
  metadata: SearchResultReportMetadata;
};

type SearchResultReportBlock = {
  id: string;
  type: 'block';
  metadata: SearchResultReportBlockMetadata;
};

type SearchResultExplore = {
  id: string;
  type: 'explore';
  metadata: SearchResultExploreMetadata;
};

type SearchResultFolder = {
  id: string;
  type: 'folder';
  metadata: SearchResultFolderMetadata;
};

export type SearchType =
  | 'user'
  | 'team'
  | 'report'
  | 'block'
  | 'explore'
  | 'folder';

export type SearchResult<T = undefined> = T extends undefined
  ? SearchResultUser | SearchResultTeam
  : T extends 'report'
    ? SearchResultReport
    : T extends 'team'
      ? SearchResultTeam
      : T extends 'user'
        ? SearchResultUser
        : T extends 'block'
          ? SearchResultReportBlock
          : T extends 'explore'
            ? SearchResultExplore
            : T extends 'folder'
              ? SearchResultFolder
              : never;

export const searchApi = createApi<SearchResult>({
  basePath: API_ENDPOINT,
  organisationSlugPosition: ORGANIZATION_SLUG_POSITION,
  paginationType: 'page',
});

const { usePaginatedList: useSearchResults, useGetPath: useGetSearchPath } =
  searchApi;

export { useSearchResults, useGetSearchPath };
