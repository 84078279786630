export enum FilterOperator {
  EQUALS = 'eq',
  NOT_EQUALS = 'neq',
  CONTAINS = 'in',
  NOT_CONTAINS = 'nin',
  GREATER_THAN = 'gt',
  GREATER_THAN_EQUALS = 'gte',
  LESS_THAN = 'lt',
  LESS_THAN_EQUALS = 'lte',
  IS_SET = 'set',
  IS_NOT_SET = 'not_set',
  BETWEEN = 'between',
  NOT_BETWEEN = 'not_between',
  TOP = 'top',
  BOTTOM = 'bottom',

  // used only on the UI side
  SEGMENT = 'segment',
}

export enum DateGranularity {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  FISCAL_MONTH = 'fiscal_month',
  FISCAL_QUARTER = 'fiscal_quarter',
  FISCAL_YEAR = 'fiscal_year',
}

export interface AnalyticQueryFilter {
  field: string;
  operator: FilterOperator;
  value?: unknown;
  format?: 'date';
  granularity?: DateGranularity;
  isCalculatedField?: boolean;
}

export function isQueryFilter(
  filter:
    | string
    | string[]
    | Record<string, unknown>
    | null
    | undefined
    | AnalyticQueryFilter,
): filter is AnalyticQueryFilter {
  return (
    filter !== null &&
    typeof filter === 'object' &&
    'field' in filter &&
    'operator' in filter &&
    !!filter.field
  );
}

export type AnalyticFieldType =
  | 'string'
  | 'email'
  | 'url'
  | 'percent'
  | 'timestamp'
  | 'primary_key'
  | 'primaryKey'
  | 'foreign_key'
  | 'foreignKey'
  | 'boolean'
  | 'currency'
  | 'number'
  // used only on the UI side
  | 'formula'
  | 'segment'
  | 'calculatedField';

export function isNumeric(type: AnalyticFieldType | null | undefined) {
  return type && ['percent', 'currency', 'number'].includes(type);
}

export type FilterableFieldType = Exclude<
  AnalyticFieldType,
  | 'primary_key'
  | 'primaryKey'
  | 'foreign_key'
  | 'foreignKey'
  | 'formula'
  | 'segment'
  | 'calculatedField'
>;

export type ColumnType = 'dimension' | 'measure' | 'parameter';
