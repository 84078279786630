import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'sonner';

import { ClientError } from '../components/ApiProvider';

export interface UseMutationTriggerConfig<TResult, TValue> {
  onSuccess?: (
    result: TResult | null,
    queryClient: QueryClient,
    value: TValue,
  ) => void;
  onError?: (e: Error) => void;
  successMessage?: React.ReactNode;
}

function useMutationTrigger<TResult, TValue = unknown>(
  mutate: (value: TValue) => Promise<TResult | null>,
  {
    onSuccess,
    onError,
    successMessage,
  }: UseMutationTriggerConfig<TResult, TValue> = {},
) {
  const queryClient = useQueryClient();
  const mutation = useMutation<TResult | null, unknown, TValue>({
    mutationFn: (value: TValue) => mutate(value),
    throwOnError: false,
  });

  const runMutation = useCallback(
    async (value: TValue) => {
      try {
        const result = await mutation.mutateAsync(value);
        onSuccess?.(result, queryClient, value);

        if (successMessage) {
          toast.success(successMessage);
        }

        return result;
      } catch (err) {
        if (err instanceof ClientError) {
          toast.error(err.message);
        }
        onError?.(err as Error);
        throw err;
      }
    },
    [mutation, queryClient, onSuccess, onError, successMessage],
  );

  return [
    runMutation,
    { isLoading: mutation.isPending, isError: mutation.isError },
  ] as const;
}

export default useMutationTrigger;
