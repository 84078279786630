import { defineMessages } from 'react-intl';

export const actions = defineMessages({
  share: {
    id: 'actions.share',
    defaultMessage: 'Share',
  },
  shareItem: {
    id: 'actions.shareItem',
    defaultMessage: "Share ''{item}''",
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
  },
  clickHere: {
    id: 'actions.clickHere',
    defaultMessage: 'click here',
  },
  copy: {
    id: 'actions.copy',
    defaultMessage: 'Copy',
  },
  edit: {
    id: 'actions.edit',
    defaultMessage: 'Edit',
  },
  undo: {
    id: 'actions.undo',
    defaultMessage: 'Undo',
  },
  redo: {
    id: 'actions.redo',
    defaultMessage: 'Redo',
  },
  view: {
    id: 'actions.view',
    defaultMessage: 'View',
  },
  delete: {
    id: 'actions.delete',
    defaultMessage: 'Delete',
  },
  remove: {
    id: 'actions.remove',
    defaultMessage: 'Remove',
  },
  search: {
    id: 'actions.search',
    defaultMessage: 'Search',
  },
  signOut: {
    id: 'actions.signOut',
    defaultMessage: 'Sign out',
  },
  signIn: {
    id: 'actions.signIn',
    defaultMessage: 'Sign in',
  },
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
  },
  show: {
    id: 'actions.show',
    defaultMessage: 'Show',
  },
  update: {
    id: 'actions.update',
    defaultMessage: 'Update',
  },
  duplicate: {
    id: 'actions.duplicate',
    defaultMessage: 'Duplicate',
  },
  drilldown: {
    id: 'actions.drilldown',
    defaultMessage: 'Click to see details',
  },
  download: {
    id: 'actions.download',
    defaultMessage: 'Download',
  },
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
  },
  apply: {
    id: 'actions.apply',
    defaultMessage: 'Apply',
  },
  clearSelected: {
    id: 'actions.clearSelected',
    defaultMessage: 'Clear selected',
  },
  activate: {
    id: 'actions.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'actions.deactivate',
    defaultMessage: 'Deactivate',
  },
  setDefault: {
    id: 'actions.setDefault',
    defaultMessage: 'Set as default',
  },
  schedule: {
    id: 'actions.schedule',
    defaultMessage: 'Schedule',
  },
  favorite: {
    id: 'actions.favorite',
    defaultMessage: 'Favorite',
  },
  favorited: {
    id: 'actions.favorited',
    defaultMessage: 'Favorited',
  },
  refresh: {
    id: 'actions.refresh',
    defaultMessage: 'Refresh',
  },
  moveUp: {
    id: 'actions.moveUp',
    defaultMessage: 'Move up',
  },
  moveDown: {
    id: 'actions.moveDown',
    defaultMessage: 'Move down',
  },
  addValue: {
    id: 'actions.addValue',
    defaultMessage: "Add ''{value}''",
  },
  searchByNameOrEmail: {
    id: 'actions.searchByNameOrEmail',
    defaultMessage: 'Search by name or email',
  },
  copyUrl: {
    id: 'actions.copyUrl',
    defaultMessage: 'Copy URL',
  },
  back: {
    id: 'actions.back',
    defaultMessage: 'Back',
  },
  exploreFromHere: {
    id: 'actions.exploreFromHere',
    defaultMessage: 'Explore from here',
  },
  fullscreen: {
    id: 'actions.fullscreen',
    defaultMessage: 'Fullscreen',
  },
  uploading: {
    id: 'actions.uploading',
    defaultMessage: 'Uploading...',
  },
  uploadSuccess: {
    id: 'actions.uploadSuccess',
    defaultMessage: 'Uploaded successfully.',
  },
  uploadError: {
    id: 'actions.uploadError',
    defaultMessage: 'Failed to upload.',
  },
  assign: {
    id: 'actions.assign',
    defaultMessage: 'Assign',
  },
  unassign: {
    id: 'actions.unassign',
    defaultMessage: 'Unassign',
  },
  toggleDetails: {
    id: 'actions.toggleDetails',
    defaultMessage: 'Toggle details',
  },
});
