import { endOfYear, startOfYear } from 'date-fns';
import identity from 'lodash/identity';
import pluralize from 'pluralize';

import type { AnalyticFieldType } from '@/api/query';
import { FilterOperator } from '@/api/query';
import type { DatasetField } from '@/api/useFields';
import { CURRENT_USER } from '@/utils/DatasetFieldsHandler.ts';
import {
  formatDate as baseFormatDate,
  getGranularityFromValue,
  parseDate,
} from '@/utils/dates';

export interface OperatorOption {
  label: string;
  value: FilterOperator;
  toTextValue: (value: any, filter: DatasetField) => string;
  isValueValid?: (value: any) => boolean;
  toFilterValue?: (value: any) => string | string[];
}

const DATE_FORMAT = 'MMM d, yyyy';
const YEAR_REGEX = /^\d{4}$/;
const formatDate = (d: string) => {
  if (YEAR_REGEX.test(d)) {
    return d;
  }

  return baseFormatDate(d, DATE_FORMAT) || d;
};
const formatFilterDate = (d: string) => {
  if (YEAR_REGEX.test(d)) {
    return d;
  }

  return baseFormatDate(d, 'yyyy-MM-dd');
};

const formatTuple =
  (formatter: (value: string) => string | number) => (values: string[]) => {
    if (!values || !Array.isArray(values) || values.length !== 2) {
      return '';
    }
    return `${formatter(values[0])} to ${formatter(values[1])}`;
  };

const createSingleDateOperator = (
  label: string,
  operator: FilterOperator,
): OperatorOption => ({
  label,
  value: operator,
  toTextValue: (d) => formatDate(Array.isArray(d) ? d[0] : d),
  toFilterValue: (d) => formatFilterDate(Array.isArray(d) ? d[0] : d),
  isValueValid: (value: unknown) =>
    (Array.isArray(value) && value.length === 1) || parseDate(value) != null,
});

const presetToTextValue = (value: string) => {
  const result =
    value
      ?.replace('last ', '')
      ?.replace('completed ', '')
      ?.replace(/[-_]/g, ' ') ?? value;

  // Special handling for 'today' and 'yesterday'
  if (result === '1 days') {
    return value?.includes('completed') ? 'yesterday' : 'today';
  }

  return result;
};

export const DATE_OPERATORS: OperatorOption[][] = [
  [
    createSingleDateOperator('Since', FilterOperator.GREATER_THAN_EQUALS),
    createSingleDateOperator('On', FilterOperator.EQUALS),
    createSingleDateOperator('Before', FilterOperator.LESS_THAN_EQUALS),
    createSingleDateOperator('Not on', FilterOperator.NOT_EQUALS),
  ],
  [
    {
      label: 'Between',
      value: FilterOperator.BETWEEN,
      toTextValue: formatTuple(formatDate),
      toFilterValue: (d) => {
        if (!Array.isArray(d) || d.length !== 2) {
          return d;
        }

        const [start, end] = d;

        // XXX: year only dates need to be handled differently
        // because years are valid JS dates and don't get parsed correctly
        if (YEAR_REGEX.test(start) && YEAR_REGEX.test(end)) {
          const yearRangeStart = startOfYear(parseDate(start));
          const yearRangeEnd = endOfYear(parseDate(end));
          return [yearRangeStart, yearRangeEnd].map(formatFilterDate);
        }

        const startFormatted = formatFilterDate(start);
        const endFormatted = formatFilterDate(end);

        // If the start and end dates are formatted correctly, return them
        if (startFormatted && endFormatted) {
          return [startFormatted, endFormatted];
        }

        // Now we check to see if they're granularity presets.
        // If they are, we return a single string with date range so that
        // the backend can parse it as a preset
        const granularity = getGranularityFromValue(start);
        if (granularity) {
          return `${start} to ${end}`;
        }

        // If we can't format the dates, we return the original value
        return d;
      },
      isValueValid: (value: unknown) =>
        Array.isArray(value) && value.length === 2,
    },
  ],
  [
    {
      label: 'Last',
      value: FilterOperator.BETWEEN,
      toTextValue: presetToTextValue,
      toFilterValue: (d) => d.replace('completed ', ''),
      isValueValid: (value: unknown) => typeof value === 'string',
    },
    {
      label: 'Last completed',
      value: FilterOperator.LESS_THAN,
      toTextValue: presetToTextValue,
      toFilterValue: (d) => {
        const date = d.replace('last ', '').replace('completed ', '');
        return `last completed ${date}`;
      },
      isValueValid: (value: unknown) => typeof value === 'string',
    },
  ],
];

const generateNumberOptions = (
  toTextValue: (value: string) => string = identity,
): OperatorOption[][] => [
  [
    {
      label: 'Equals',
      value: FilterOperator.EQUALS,
      toTextValue,
    },
    {
      label: 'Does not equal',
      value: FilterOperator.NOT_EQUALS,
      toTextValue,
    },
    {
      label: 'Greater than',
      value: FilterOperator.GREATER_THAN,
      toTextValue,
    },
    {
      label: 'At least',
      value: FilterOperator.GREATER_THAN_EQUALS,
      toTextValue,
    },
    {
      label: 'Less than',
      value: FilterOperator.LESS_THAN,
      toTextValue,
    },
    {
      label: 'At most',
      value: FilterOperator.LESS_THAN_EQUALS,
      toTextValue,
    },
  ],
  [
    {
      label: 'Between',
      value: FilterOperator.BETWEEN,
      toTextValue: formatTuple(toTextValue),
    },
    {
      label: 'Not between',
      value: FilterOperator.NOT_BETWEEN,
      toTextValue: formatTuple(toTextValue),
    },
  ],
  [
    {
      label: 'Top',
      value: FilterOperator.TOP,
      toTextValue: identity,
    },
    {
      label: 'Bottom',
      value: FilterOperator.BOTTOM,
      toTextValue: identity,
    },
  ],
];

const NUMBER_OPERATORS = generateNumberOptions();
export const ALL_NUMBER_OPERATORS = NUMBER_OPERATORS.flat();

const PERCENT_OPERATORS = generateNumberOptions((value: string) => {
  if (value == null) {
    return '';
  }
  const percent = parseFloat(value);
  if (Number.isNaN(percent)) {
    return '';
  }

  return `${percent * 100}%`;
});

export const ALL_PERCENT_OPERATORS = PERCENT_OPERATORS.flat();

const CURRENCY_OPERATORS = generateNumberOptions((value: string) => {
  if (value == null) {
    return '';
  }
  const currency = parseFloat(value);
  if (Number.isNaN(currency)) {
    return '';
  }

  return `$${currency}`;
});

export const ALL_CURRENCY_OPERATORS = CURRENCY_OPERATORS.flat();

const toTextValue = (d: string | string[], filter: DatasetField) => {
  if (!Array.isArray(d)) {
    return d === CURRENT_USER ? 'Current user' : d;
  }

  if (d.length === 1) {
    return d[0] === CURRENT_USER ? 'Current user' : d[0];
  }
  return pluralize(filter.label.toLowerCase(), d.length, true);
};

const TEXT_OPERATORS: OperatorOption[][] = [
  [
    {
      label: 'Equals',
      value: FilterOperator.EQUALS,
      toTextValue,
    },
    {
      label: 'Does not equal',
      value: FilterOperator.NOT_EQUALS,
      toTextValue,
    },
    {
      label: 'Contains',
      value: FilterOperator.CONTAINS,
      toTextValue,
    },
    {
      label: 'Does not contain',
      value: FilterOperator.NOT_CONTAINS,
      toTextValue,
    },
  ],
  [
    {
      label: 'Is not blank',
      value: FilterOperator.IS_SET,
      toTextValue: () => 'Blank',
    },
    {
      label: 'Is blank',
      value: FilterOperator.IS_NOT_SET,
      toTextValue: () => 'Blank',
    },
  ],
];

export const ALL_TEXT_OPERATORS = TEXT_OPERATORS.flat();

export const PARAMETER_OPERATORS: OperatorOption[][] = [
  [
    {
      label: 'Equals',
      value: FilterOperator.EQUALS,
      toTextValue,
    },
  ],
];

export const ALL_PARAMETER_OPERATORS = PARAMETER_OPERATORS.flat();

const BOOLEAN_OPERATORS: OperatorOption[][] = [
  [
    {
      label: 'Equals',
      value: FilterOperator.EQUALS,
      toTextValue: (d) => (d === 'true' ? 'Yes' : 'No'),
    },
  ],
];

export const TYPE_TO_OPERATORS: Record<AnalyticFieldType, OperatorOption[][]> =
  {
    string: TEXT_OPERATORS,
    email: TEXT_OPERATORS,
    url: TEXT_OPERATORS,
    foreignKey: TEXT_OPERATORS,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    foreign_key: TEXT_OPERATORS,
    primaryKey: TEXT_OPERATORS,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    primary_key: TEXT_OPERATORS,
    timestamp: DATE_OPERATORS,
    boolean: BOOLEAN_OPERATORS,
    number: NUMBER_OPERATORS,
    percent: PERCENT_OPERATORS,
    currency: CURRENCY_OPERATORS,
    calculatedField: TEXT_OPERATORS,
    formula: [],
    segment: [],
  };
