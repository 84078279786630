import { camelCase } from 'lodash';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';

type RequestBody = Record<string, unknown> | unknown[];

const deepMapObjects =
  (replaceKeyFunc: (key?: string) => string, whitelistKeys: string[]) =>
  (result: Record<string, any>, value: any, key: string) => {
    if (value instanceof Date) {
      value = value.toJSON();
    } else if (isObject(value) && !whitelistKeys.includes(key)) {
      value = transform(value, deepMapObjects(replaceKeyFunc, whitelistKeys));
    }
    result[replaceKeyFunc(key)] = value;
    return result;
  };

export function deepMapToSnakeCase(
  value: RequestBody,
  whitelistKeys: string[] = [],
) {
  return transform(value, deepMapObjects(snakeCase, whitelistKeys));
}

export function deepMapToCamelCase(
  value: Record<any, any>,
  whitelistKeys: string[] = [],
) {
  return transform(value, deepMapObjects(camelCase, whitelistKeys));
}

export default function getPayloadData(
  value: RequestBody,
  whitelistKeys: string[] = [],
) {
  return JSON.stringify(deepMapToSnakeCase(value, whitelistKeys));
}
