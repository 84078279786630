import useDebouncedCallback from '@restart/hooks/useDebouncedCallback';
import noop from 'lodash/noop';
import React, { useContext, useMemo } from 'react';
import { useStore } from 'zustand';

import {
  type ColorMappingStoreValue,
  useColorMappingStore,
} from './useColorMappingStore';
import type { ColorMapping } from '../../api/useReportsApi';
import { useTheme } from '../ThemeProvider';

interface ChartThemeContextValue {
  axisLabel: string;
  axisLine: string;
  annotationLine: string;
  store: ReturnType<typeof useColorMappingStore>;
}

const DEFAULT_THEME = {
  axisLabel: '#1d2025',
  axisLine: '#ddd',
  annotationLine: 'rgba(0,0,0,0.9)',
};

const DEFAULT_DARK_THEME = {
  axisLabel: '#e7e7e8',
  axisLine: '#52555a',
  annotationLine: 'rgba(114,114,114,0.9)',
};

const ChartThemeContext = React.createContext<ChartThemeContextValue>({
  ...DEFAULT_THEME,
} as any);

export function useChartTheme() {
  return useContext(ChartThemeContext);
}

export function useColorMappingSelector<T>(
  selector: (state: ColorMappingStoreValue) => T,
) {
  const { store } = useChartTheme();
  return useStore(store, selector);
}

interface ChartThemeProviderProps {
  children: React.ReactNode;
  initialColorMapping?: ColorMapping;
  onChange?: (colorMapping: ColorMapping) => void;
}

export function ChartThemeProvider({
  children,
  initialColorMapping = {},
  onChange = noop,
}: ChartThemeProviderProps) {
  const appTheme = useTheme();
  const theme = useMemo(() => {
    return appTheme.theme === 'dark' ? DEFAULT_DARK_THEME : DEFAULT_THEME;
  }, [appTheme]);

  const onChangeDebounced = useDebouncedCallback(onChange, 1000);

  const colorMappingStore = useColorMappingStore({
    initialColorMapping,
    onChange: onChangeDebounced,
  });

  const contextValue = useMemo(
    () => ({
      ...theme,
      store: colorMappingStore,
    }),
    [theme, colorMappingStore],
  );

  return (
    <ChartThemeContext.Provider value={contextValue}>
      {children}
    </ChartThemeContext.Provider>
  );
}
