import {
  QueryClientProvider as BaseQueryClientProvider,
  QueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryOnMount: false,
      throwOnError: true,
    },
  },
});

const isLocalEnv = process.env?.EQT_ENV === 'local';

function QueryClientProvider({ children }: { children: React.ReactNode }) {
  return (
    <BaseQueryClientProvider client={queryClient}>
      {children}
      {isLocalEnv && <ReactQueryDevtools initialIsOpen={false} />}
    </BaseQueryClientProvider>
  );
}

export default QueryClientProvider;
