import { createContext, useContext } from 'react';

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type RequestBody = Record<string, unknown> | unknown[] | FormData;

interface Api {
  request<TResult, TBody = RequestBody>(
    method: Method,
    path: string,
    data?: TBody,
    whitelistKeys?: false | string[],
    signal?: AbortSignal,
  ): Promise<TResult | null>;

  get<TResult>(
    path: string,
    queryParams?: Record<string, unknown>,
    whitelistKeys?: false | string[],
    signal?: AbortSignal,
  ): Promise<TResult | null>;

  post<TResult, TBody = RequestBody>(
    path: string,
    data?: TBody,
    queryParams?: Record<string, unknown>,
    whitelistKeys?: false | string[],
    signal?: AbortSignal,
  ): Promise<TResult | null>;

  patch<TResult, TBody = RequestBody>(
    path: string,
    data?: TBody,
    queryParams?: Record<string, unknown>,
    whitelistKeys?: false | string[],
  ): Promise<TResult | null>;

  put<TResult, TBody = RequestBody>(
    path: string,
    data?: TBody,
    queryParams?: Record<string, unknown>,
    whitelistKeys?: false | string[],
  ): Promise<TResult | null>;

  delete<TResult, TBody = RequestBody>(
    path: string,
    data?: TBody,
    queryParams?: Record<string, unknown>,
  ): Promise<TResult | null>;
}

export const ApiContext = createContext<Api>(null as any);

export const useApi = () => useContext(ApiContext);
