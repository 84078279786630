const EMPTY = { r: 0, g: 0, b: 0 };

function hexToRGB(hex: string): { r: number; g: number; b: number } {
  if (!hex) {
    return EMPTY;
  }
  const originalString = hex.trim();
  const hasPoundSign = originalString[0] === '#';
  const originalColor = hasPoundSign ? originalString.slice(1) : originalString;

  if (originalColor.length !== 6) {
    console.warn(`Incorrectly formatted color string: ${hex}.`);
    return EMPTY;
  }

  const originalBase16 = parseInt(originalColor, 16);

  const r = originalBase16 >> 16;

  const g = (originalBase16 >> 8) & 0x00ff;

  const b = originalBase16 & 0x0000ff;
  return { r, g, b };
}

/**
 * Returns a lightened or darkened color, similar to SCSS darken()
 * @param hex e.g. '#FF0000'
 * @param pct percentage amount to lighten or darken, e.g. -20
 * @param opacity number from 0-1 indicating the opacity
 */
export function lightenDarkenColor(
  hex: string,
  pct: number,
  opacity = 1,
): string {
  function output(val: number): number {
    return Math.max(0, Math.min(255, val));
  }

  const amt = Math.round(2.55 * pct);
  let { r, g, b } = hexToRGB(hex);

  r = output(r + amt);
  g = output(g + amt);
  b = output(b + amt);

  return `rgba(${[r, g, b].join(',')}, ${opacity})`;
}
