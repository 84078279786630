import cx from 'clsx';

import { Icon } from '@/components/IconPicker/IconPicker.tsx';
import { Avatar, AvatarProps } from '@/components/ui/avatar';

export interface IconAvatarProps {
  icon?: string | null | undefined;
  color?: string | null | undefined;
  size?: AvatarProps['size'];
  className?: string;
  defaultIcon?: React.ReactNode;
}

export function IconAvatar({
  icon,
  color,
  size,
  className,
  defaultIcon,
}: IconAvatarProps) {
  return (
    <Avatar
      size={size}
      className={cx(className, color && '!outline-none')}
      rounded="square"
      data-color={color}
      icon={
        icon ? (
          <Icon name={icon as any} className="size-[1.5em]" />
        ) : (
          defaultIcon
        )
      }
    />
  );
}
