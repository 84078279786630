import { useLocalStorage } from '@eqtble/utils';
import pick from 'lodash/pick';

export function clearReportEditCache(storyId: string) {
  localStorage.removeItem(`eqtble.story.${storyId}`);
  localStorage.removeItem(`eqtble.story.${storyId}.blocks`);
}

// XXX: using import to avoid circular dependencies
type Report = import('../../../api/useReportsApi').Report;
type ReportBlock = import('../../../api/useReportsBlocksApi').ReportBlockEditor;

const KEYS_TO_UPDATE: (keyof Report)[] = [
  'title',
  'description',
  'colorMapping',
  'chartTheme',
  'filters',
  'tags',
  // Needed for actions and updates
  'id',
  'viewerCollaboratorRole',
  'updatedAt',
];

type StoryFormCache = Pick<Report, (typeof KEYS_TO_UPDATE)[number]>;

export function useStoryFormCache(story: Report) {
  return useLocalStorage<StoryFormCache>(
    `eqtble.story.${story.id}`,
    pick(story, KEYS_TO_UPDATE),
  );
}

export function useStoryFormBlocksCache(
  storyId: string,
  initialBlocks?: ReportBlock[],
) {
  return useLocalStorage<ReportBlock[]>(
    `eqtble.story.${storyId}.blocks`,
    initialBlocks ?? [],
  );
}
